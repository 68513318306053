/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
main{
  width:100%;
  min-width: $min-width;
}
[class*='content-left']{
  .content{
    &__box{
      padding: 0 10% 0 0;
    }
    &-foto{
      justify-content: flex-start;
    }
  }
}
[class*='content-right']{
  .content{
    &__box{
      padding: 0 0 0 10%;
    }
    &-foto{
      justify-content: flex-end;
    }
  }
}
.content{
  width: 100%;
  section{
    padding: 5%;
    position: relative;
    overflow: hidden;
    &:not(.slider-services){
      & > .title, & > .title-invert{
        margin: 0 0 5% 0;
        position: relative;
        z-index: 1;
      }
    }
    &.content{
      &__contacts{
        padding: 5% 0 15px 0;
      }
    }
  }
  &__box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
  }
  &__item{
    display: inline-block;
    padding: 2%;
    margin: 1rem;
    border: 1px solid $gray;
    &-icon{
      height: 65px;
      margin: 20px 0 0 0;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 3px;
        background: $btn-color;
        transform: scale(0.5);
        transform-origin: center center;
        transition: all 0.4s linear;
      }
      img{
        display: block;
        box-sizing: border-box;
        height: 100%;
        margin: auto;
      }
    }
    &:hover{
      border: 1px solid $btn-color;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
      //filter: drop-shadow(1px 1px 1px $gray);
    }
  }
  &__service{
    .content{
      &__box{
        margin: 2%;
      }
      &__item{
        width: 300px;
        height: 300px;
        margin: 0;
        background: $nav-color;
        &:hover{
          .content__item-icon:after{
            transform: scale(1);
          }
        }
        h3{
          margin: 40px 0 0 0;
          text-align: center;
        }
      }
    }
  }
  &__howWeWork{
    .content{
      &__item{
        width: 220px;
        height: 220px;
        padding: 1%;
        border: none;
        border-bottom: 1px solid $gray;
        border-right: 1px solid $gray;
        position: relative;
        &:after{
          content: attr(data-number);
          position: absolute;
          top: -30px;
          left: -5px;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: $gold;
          font: normal 700 70px / 120% $title-font-family;
        }
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 180px;
          border-left: 1px solid $gray;
        }
        h3{
          text-align: center;
          padding: 0 0 0 25px;
        }
        h3, p {
          color: $white;
        }
        span.border{
          width: 180px;
          height: 1px;
          background: $gray;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    &_row{
      display: flex;
    }
  }
  &__comment{
    .content{
      &__box{
        align-items: stretch;
        padding: 0 0 60px 0;
      }
      &__item{
        width: 30%;
        height: auto;
        padding: 30px 1rem 1rem 1rem;
        position: relative;
        &:before{
          content: '';
          position: absolute;
          top: -30px;
          left: 30px;
          min-width: 40px;
          width: 12%;
          max-height: 60px;
          height: 100%;
          background: url("../images/comment.png") center center / contain no-repeat;
        }
        span{
          position: absolute;
          top: 0;
          right: 30px;
        }
        h3{
          position: absolute;
          top: -30px;
          right: 30px;
        }
      }
    }
  }
  &__contacts{
    & > .content {
      &__box {
        align-items: stretch;
      }
    }
    [class*='btn']{
      margin: 0;
    }
    [class*='content-left']{
      padding: 0 0 0 5%;
      background:  linear-gradient(to right, rgba(16,44,36,1) 0%, rgba(25,83,69,1) 100%);
      .content{
        &__box{
          padding: 1% 5% 1% 1%;
        }
        &-foto{
          max-height: 300px;
          img{
            width: auto;
            height: 100%;
          }
        }
        &-name{
          margin: 0 0 5% 0;
          h4, p{
            text-align: right;
          }
        }
      }
      [class*='btn']{
        align-self: flex-end;
      }
    }
    [class*='content-right']{
      padding: 0 5% 0 0;
      background: linear-gradient(to right, rgba(43,48,47,1) 0%, rgba(29,35,33,1) 100%);
      .content{
        &__box{
          padding: 1% 1% 1% 5%;
        }
        &-foto{
          max-height: 300px;
          img{
            width: auto;
            height: 100%;
          }
        }
        &-name{
          margin: 0 0 5% 0;
          h4, p{
            text-align: left;
          }
        }
      }
    }
  }
}
[class*='content-left'], [class*='content-right']{
  flex: 1 1 50%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  .content{
    &__box{
      max-width: 580px;
      min-width: 480px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-foto{
      flex: 0 1 auto;
      display: flex;
      align-items: flex-end;
      img{
        display: block;
        box-sizing: border-box;
        width: 100%;
      }
    }
    &-name{
      margin: 0 0 15% 0;
      h4{
        padding: 0;
        color: $white;
        font: normal 500 1.5rem / 130% $title-font-family;
        text-align: center;
        text-shadow: 1px 1px 1px $nav-color-current;
      }
      p{
        padding: 2.5% 0;
        color: $white;
        font: normal 400 1.2rem / 120% $base-font-family;
        text-align: center;
      }
    }
  }
}
.map {
  width: 100%;
  padding: 0 !important;
  background: none !important;
  display: flex;
  justify-content: center;
  align-self: stretch;
  h3{
    color: $text-color;
    font: normal 700 1.6rem / 100% $title-font-family;
    text-align: center;
  }
  a{
    color: $link;
    font: italic 400 1.2rem / 100% $base-font-family;
  }
}
[class*='slider']{
  ul{
    list-style: none;
  }
}
.slider{
  &-service{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    ul{
      list-style: none;
    }
    .slider{
      &-target {
        display: none;
        li {
          max-width: 65px;
          width: 65px;
          max-height: 65px;
          height: 65px;
          flex: auto;
          border: 1px solid $gray;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover, &.slider-target-current{
            background: $gold;
          }
        }
        img {
          display: block;
          box-sizing: border-box;
          width: auto;
          height: 50px;
          object-fit: cover;
          object-position: top left;
        }
      }
      &-item {
        width: 100%;
        height: auto;
        overflow: hidden;
        li {
          margin: 1rem;
        }
      }
    }
  }
}
.canvas{
  box-sizing: border-box;
  width: 100%;
}

/* ---- SERVICES, PRACTICE AND ARTICLE PAGE---- */
.services, .practice, .article{
  .form{
    &-main{
      background: url("../images/bg-howWeWork.jpg") 50% 50% / cover no-repeat;
    }
    &__box{
      align-items: stretch;
    }
    &__group{
      flex: 1 1 100%;
      margin: 1rem;
      &-check{
        .label{
          min-width: 360px;
        }
      }
    }
    .text-input, .textarea, .select, .label, .check-label{
      border: none;
      color: $white;
    }
    .text-input, .textarea, .select{

    }
    &__btn{
      //align-self: center;
      margin: 20px auto;
    }
  }
}
/* ---- SERVICES PAGE---- */
.slider{
  &-services{
    display: flex;
    position: relative;
    .title{
      //padding: 0 0 0 380px;
      position: absolute;
      top: 10%;
      left: 5%;
    }
    .slider{
      &-target {
        max-width: 380px;
        min-width: 380px;
        padding: 1%;
        border: 1px solid $gray;
        li {
          width: 100%;
          height: auto;
          margin: 10px 0;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          &:hover, &.slider-target-current{
            span{
              &:last-of-type{
                font-weight: 700;
                &:after{
                  width: 100%;
                  height: 3px;
                }
              }
            }
          }
        }
        &-icon{
          min-width: 70px;
        }
        img {
          display: block;
          box-sizing: border-box;
          width: auto;
          height: 50px;
          object-fit: cover;
          object-position: top left;
        }
        span{
          &:last-of-type{
            display: inline-block;
            position: relative;
            &:after{
              content: '';
              position: absolute;
              left: 0px;
              bottom: -8px;
              width: 40px;
              height: 1px;
              background: $btn-color;
              transform-origin: left center;
              transition: all 0.4s linear;
            }
          }
        }
      }
      &-item {
        padding: 0 3%;
        overflow: hidden;
        position: relative;
        li {
          margin: 0;
          background: $nav-color;
          align-self: flex-start;
          position: absolute;
          top: 7%;
          left: 1%;
          z-index: 1;
          opacity: 0;
          &.slider-item-target {
            width: 100%;
            position: relative;
            z-index: 3;
            animation: slide-services 1s 1;
            opacity: 1;
          }
        }
        h3{
          padding: 5px 0 5px 30px;
        }
        p{
          padding: 5px 0 5px 30px;
          white-space: pre-wrap;
          position: relative;
          &:after{
            content: ' ';
            display: block;
            position: absolute;
            left: 0px;
            bottom: 50%;
            width: 20px;
            height: 10px;
            border-left: solid 3px $btn-color;
            border-bottom: solid 3px $btn-color;
            transform: rotate(-45deg);
          }
        }
        @keyframes slide-services {
          0% { transform: translateX(-100%); opacity: 0;}
          100% { transform: translateX(0%); opacity: 1;}
        }
        &:not(.slider-item-target) {
          animation: hidden-services 1s 1;
        }
        @keyframes hidden-services {
          0% { z-index: 2; opacity: 0;}
          100% { z-index: 2; opacity: 0;}
        }
      }
    }
  }
}
/* ---- PRACTICE PAGE---- */
.practice{
  & > .content {
    &__box {
      align-items: stretch;
    }
  }
  &__column{
    flex: 1 1 50%;
    padding: 1rem;
  }
  &__item{
    padding: 0 0 40px 0;
    position: relative;
    &:before{
      width: 0;
    }
    &:not(:last-child){
      &:before, &:after{
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        transform: scale(0.5);
        transform-origin: center top;
        transition: all 0.4s linear;
      }
      &:before{
        top: auto;
        bottom: 7px;
        z-index: 1;
      }
      &:after{
        height: 3px;
        background-color: $btn-color;
        bottom: 20px;
        z-index: 2;
      }
      &:hover{
        &:before, &:after{
          transform: scale(1);
        }
      }
    }
    p{
      margin: 10px 0;
    }
    a{
      color: $link;
      &:link, &:active, &:visited {
        color: $link;
      }
    }
  }
}
/* ---- ARTICLE PAGE---- */
.article{
  &__item{
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 40px 10px;
    position: relative;
    &:before{
      width: 0;
    }
    &:not(:last-child){
      &:before, &:after{
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        transform: scale(0.5);
        transform-origin: center top;
        transition: all 0.4s linear;
      }
      &:before{
        top: auto;
        bottom: 7px;
        z-index: 1;
      }
      &:after{
        height: 3px;
        background: $nav-color-current;
        bottom: 20px;
        z-index: 2;
      }
      &:hover{
        &:before, &:after{
          transform: scale(1);
        }
      }
    }
    &_pict{
      min-width: 270px;
      width: 270px;
      margin: 0 10px 10px 0;
      display: inline;
      float: left;
      img{
        display: block;
        box-sizing: border-box;
        width: 100%;
      }
    }
    &_text{
      display: inline;
      margin: 0 10px 10px 0;
    }
    &_date{
      position: absolute;
      top: 10px;
      right: 1rem;
      span{
        padding: 0 0 0 40px;
        color: $gray;
        position: relative;
        &:after{
          content: url("../images/svg-calendar.svg");
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
        }
      }
    }
    p{
      margin: 10px 0;
    }
    a{
      color: $link;
      &:link, &:active, &:visited {
        color: $link;
      }
    }
  }
}
/* ---- CONTACTS PAGE---- */
.contacts{
  section{
    padding: 5% 0 0 0 !important;
    & > .content {
      &__box {
        align-items: stretch;
        &:nth-of-type(1){
          background:  linear-gradient(to right, rgba(16,44,36,1) 0%, rgba(25,83,69,1) 100%);
        }
        &:nth-of-type(3){
          background: linear-gradient(to right, rgba(43,48,47,1) 0%, rgba(29,35,33,1) 100%);
        }
      }
    }
  }
  [class*='content-left'],[class*='content-right']{
    .content{
      &__box{
        justify-content: flex-start;
      }
      &-name{
        margin: 0;
      }
    }
    &.form{
      &-contacts {
        align-items: flex-start;
      }
    }
    [class*='btn']{
      margin: 0;
    }
  }
  [class*='content-left']{
    padding: 0 0 0 5%;
    .content{
      &__box{
        padding: 2rem 1rem 2rem 1%;
      }
    }
    &.form{
      &-contacts {
        padding: 2rem 1rem 2rem 5%;
        justify-content: flex-end;
      }
    }
    [class*='btn']{
      align-self: flex-end;
    }
    h4, p{
      text-align: right;
    }
  }
  [class*='content-right']{
    padding: 0 5% 0 0;
    .content{
      &__box{
        padding: 2rem 1% 2rem 1rem;
      }
    }
    &.form{
      &-contacts {
        padding: 2rem 5% 2rem 1rem;
        justify-content: flex-start;
      }
    }
    h4, p{
      text-align: left;
    }
  }
  .map{
    height: 450px;
  }
  .form{
    max-width: 600px;
    margin: 0;
    &-contacts{
      h4{
        padding: 0;
        color: $white;
        font: normal 500 1.5rem / 130% $title-font-family;
        text-shadow: 1px 1px 1px $nav-color-current;
      }
    }
    &__box{
      flex-direction: column;
      align-items: stretch;
    }
    &__group{
      flex: 1 1 100%;
      margin: 1rem 0;
      &-check{
        .check-input_label{
          order: 1;
        }
        .label{
          min-width: auto;
          order: 2;
        }
      }
    }
    .label{
      min-width: 170px;
    }
    .text-input, .textarea, .select, .label, .check-label{
      border: none;
      color: $white;
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body{min-height: 100vh; width: 100%; display: flex; flex-direction: column;}
}




