@font-face {
	font-family: 'TrajanPro';
	src: url('../fonts/TrajanPro-Regular.ttf');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova/ProximaNova-Regular.eot');
	src: url('../fonts/ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-FFFD;
}



