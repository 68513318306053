/* reset */
/* main variables */
body {
  margin: 0px;
  padding: 0px;
  font-family: "Rubik", sans-serif;
  background-color: #fffee0;
  height: 100%;
  line-height: 1;
  position: relative;
  font-size: 1.2rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px; }

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none; }

input,
textarea {
  color: #333;
  font-family: "Rubik", sans-serif;
  outline: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none; }

input[type="button"],
input[type="submit"],
button {
  cursor: pointer; }

td {
  margin: 0px;
  padding: 0px; }

form {
  padding: 0px;
  margin: 0px; }

a {
  color: #222222;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a,
span,
div,
button {
  outline: none !important; }

input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.clearfix:after,
.wrapper:after,
.row:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

@font-face {
  font-family: 'TrajanPro';
  src: url("../fonts/TrajanPro-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD; }

@font-face {
  font-family: 'ProximaNova';
  src: url("../fonts/ProximaNova/ProximaNova-Regular.eot");
  src: url("../fonts/ProximaNova/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD; }

/* BEGIN SELECTATOR PLUGIN */
.single .selectator_selected_items {
  display: flex;
  align-items: center;
  height: 100%; }

.single .selectator_selected_items .selectator_selected_item .selectator_selected_item_title {
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 80%;
  font-family: "Rubik", sans-serif; }

.selectator_element.single {
  width: 100% !important;
  margin: 10px 0;
  padding: 0 20px 0 10px !important;
  border: none;
  border-radius: 2px;
  background: transparent;
  cursor: pointer; }

.selectator_element:after {
  content: ' ';
  position: absolute;
  top: 15px !important;
  right: 5px !important;
  width: 0;
  height: 0;
  overflow: hidden;
  border-top: 9px solid #fffee0;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  background: none;
  transform: none; }

.selectator_element:hover {
  box-shadow: none; }

.selectator_element.focused {
  box-shadow: none; }

.selectator_options {
  background: #f4f1f1; }

.selectator_options .selectator_option {
  padding: 3px 5px;
  min-height: 18px;
  background: #f4f1f1;
  -webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0);
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0);
  border: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center; }

.selectator_options .selectator_option .selectator_option_left img {
  height: 20px; }

.selectator_options .selectator_option .selectator_option_title {
  margin-left: 5px; }

.form__group-country .single .selectator_selected_items .selectator_selected_item .selectator_selected_item_title {
  margin-left: 45px; }

.selectator_options .selectator_option_title {
  font-size: 1.2rem; }

.selectator_options .selectator_option.active {
  background-color: #1c6352; }

.single .selectator_selected_items .selectator_selected_item .selectator_selected_item_left {
  margin-top: 10px; }

/* END SELECTATOR PLUGIN */
/* Slider */
/*.slick-dotted.slick-slider {
    margin-bottom: 30px;
}*/
.slick-dots li {
  height: 15px;
  width: 15px;
  margin: 0 5px; }

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 15px;
  width: 15px;
  padding: 5px; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  content: " ";
  width: 20px;
  height: 20px;
  border: 1px solid #1c6352;
  border-radius: 2px;
  background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%);
  opacity: 0.7; }

.slick-dots li button:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 3px;
  top: 4px;
  width: 14px;
  height: 7px;
  border-left: solid 3px #1c6352;
  border-bottom: solid 3px #1c6352;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0; }

.slick-dots li.slick-active button:after {
  opacity: 1.0; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 100;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  content: '';
  opacity: .75;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev:before {
  border-right: 50px solid #cbcaca; }

[dir='rtl'] .slick-prev:before {
  border-right: 50px solid #cbcaca; }

.slick-next:before {
  border-left: 50px solid #cbcaca; }

[dir='rtl'] .slick-next:before {
  border-left: 50px solid #cbcaca; }

.content__comment .slick-list {
  padding: 30px 0 0 0 !important; }

.content__comment .slick-track {
  display: flex;
  align-items: stretch; }

.content__comment .slick-dots {
  width: auto;
  left: calc(50% - 50px);
  bottom: 10px; }

.content__comment .slick-prev {
  left: -50px; }

.content__comment [dir='rtl'] .slick-prev {
  right: -50px;
  left: auto; }

.content__comment .slick-prev:before,
.content__comment .slick-next:before {
  font-size: 0px;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent; }

.content__comment .slick-prev:before {
  border-right: 30px solid #1c6352; }

.content__comment [dir='rtl'] .slick-prev:before {
  border-right: 30px solid #1c6352; }

.content__comment .slick-next {
  right: -50px; }

.content__comment [dir='rtl'] .slick-next {
  right: auto;
  left: -50px; }

.content__comment .slick-next:before {
  border-left: 30px solid #1c6352; }

.content__comment [dir='rtl'] .slick-next:before {
  border-left: 30px solid #1c6352; }

/*
================================================================================
|                                     ALL                                |
================================================================================
*/
/* ---- MAIN STYLES ---- */
html {
  font-size: 16px; }

.main-wrapper {
  max-width: 1920px;
  min-width: 320px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; }
  .main-wrapper.home .footer__join {
    display: block !important; }

.main-section {
  max-width: 1500px;
  min-width: 1500px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between; }

/* ---- TITLES ---- */
*a:link, *a:hover, *a:active, *a:visited {
  text-decoration: none; }

p {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #222222;
  text-align: justify;
  font-weight: 400; }

h1 {
  font-size: 5.5rem;
  line-height: 5.5rem;
  color: #222222;
  font-family: "TrajanPro";
  font-weight: 700; }

h2 {
  font-size: 2.1rem;
  line-height: 2.1rem;
  color: #222222;
  font-family: "TrajanPro";
  font-weight: 400; }

h3 {
  font-size: 1.5rem;
  line-height: 100%;
  color: #222222;
  font-family: "Rubik", sans-serif;
  font-weight: 700; }

.title, .title-invert {
  width: 100%;
  font: normal 700 2.625rem/100% "TrajanPro";
  text-align: center; }

.title {
  color: #222222; }
  .title-invert {
    color: #fffee0; }

/* ---- LOGO ---- */
.logo {
  display: block;
  width: 100px;
  position: relative; }
  .logo:link, .logo:hover, .logo:active, .logo:visited {
    text-decoration: none; }
  .logo img {
    box-sizing: border-box;
    width: 100%; }

/* ---- BACKGROUND RESPONSIVE---- */
[class*='content-bg'] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden; }
  [class*='content-bg'] img {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%; }

/* ---- DECORATIVE ELEMENTS---- */
[class*='line']:after {
  position: absolute;
  left: 0;
  bottom: -4px;
  z-index: 2; }

[class*='line-gold']:after {
  content: url("../images/line-gold.png"); }

[class*='line-green']:after {
  content: url("../images/line-green.png"); }

[class*='shadow']:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 15px;
  background: linear-gradient(to bottom, rgba(46, 45, 45, 0.8) 0%, rgba(46, 45, 45, 0.4) 30%, rgba(46, 45, 45, 0.1) 50%, transparent 100%); }

/* ---- NAVIGATION ---- */
[class*="nav"] {
  flex: 1 1 100%; }
  [class*="nav"] ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px; }
    [class*="nav"] ul li {
      display: inline-block;
      margin: 0 5%; }

.nav__link {
  font-size: 1.4rem;
  white-space: nowrap;
  position: relative; }
  .nav__link:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    transform: scale(0);
    transform-origin: center center;
    transition: all 0.4s linear; }
  .nav__link:link, .nav__link:active, .nav__link:visited {
    color: #fffee0; }
  .nav__link:hover:after {
    transform: scale(1); }
  .nav__link-current:after {
    transform: scale(1); }

.nav-header .nav__link {
  color: #fffee0; }
  .nav-header .nav__link:after {
    background: #fffee0; }
  .nav-header .nav__link:link, .nav-header .nav__link:active, .nav-header .nav__link:visited {
    color: #fffee0; }
  .nav-header .nav__link-current {
    color: #e4d36c !important; }
    .nav-header .nav__link-current:after {
      background: #e4d36c; }

.nav-footer .nav__link {
  color: #222222; }
  .nav-footer .nav__link:after {
    background: #222222; }
  .nav-footer .nav__link:link, .nav-footer .nav__link:active, .nav-footer .nav__link:visited {
    color: #222222; }
  .nav-footer .nav__link-current {
    color: #222222 !important; }
    .nav-footer .nav__link-current:after {
      background: #222222; }

.hamburger-box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000; }

.hamburger-menu {
  position: relative;
  width: 60px;
  height: 60px;
  pointer-events: none;
  transition: 0.1s; }
  .hamburger-menu svg {
    position: absolute;
    top: -25px;
    left: -25px;
    transform: scale(1);
    transform-origin: 0 0; }
    .hamburger-menu svg path {
      stroke-width: 5px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: transparent; }
      .hamburger-menu svg path:nth-of-type(1) {
        stroke-dashoffset: 580.315px;
        stroke-dasharray: 290.158, 298.158, 24; }
      .hamburger-menu svg path:nth-of-type(2) {
        stroke-dashoffset: 80px;
        stroke-dasharray: 40, 48, 24; }
      .hamburger-menu svg path:nth-of-type(3) {
        stroke-dashoffset: 699.312px;
        stroke-dasharray: 349.656, 357.656, 24; }
  .hamburger-menu-header svg path {
    stroke: url("#grad") #e4d36c; }

.hamburger-buton {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0; }
  .hamburger-buton:hover, .hamburger-buton:focus {
    outline: none; }

.pushed .nav-header {
  transform: perspective(2000px) rotateY(0deg); }

.pushed .header__content, .pushed main, .pushed footer {
  transition: all 0.5s linear;
  position: relative; }
  .pushed .header__content:after, .pushed main:after, .pushed footer:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }

.open-0 {
  animation: open-A 0.5s linear forwards; }

@keyframes open-A {
  0% {
    stroke-dasharray: 290.158, 298.158, 24; }
  100% {
    stroke-dasharray: 290.158, 525.815, 24; } }

.open-1 {
  animation: open-B 0.5s linear forwards; }

@keyframes open-B {
  0% {
    stroke-dasharray: 40, 48, 24; }
  100% {
    stroke-dasharray: 40, 60, 0; } }

.open-2 {
  animation: open-C 0.5s linear forwards; }

@keyframes open-C {
  0% {
    stroke-dasharray: 349.656, 357.656, 24; }
  100% {
    stroke-dasharray: 349.656, 644.812, 24; } }

.open-scale {
  animation: open-scale 0.5s linear forwards; }

@keyframes open-scale {
  0% {
    transform: scale(0.5);
    top: 0px;
    left: 0px; }
  100% {
    transform: scale(1);
    top: -25px;
    left: -25px; } }

.close-0 {
  animation: close-A 0.5s linear forwards, shake-A 0.3s linear forwards 0.5s; }

@keyframes close-A {
  0% {
    stroke-dasharray: 290.158, 525.815, 24; }
  100% {
    stroke-dasharray: 290.158, 298.158, 24; } }

.close-1 {
  animation: close-B 0.5s linear forwards; }

@keyframes close-B {
  0% {
    stroke-dasharray: 40, 60, 0; }
  100% {
    stroke-dasharray: 40, 48, 24; } }

.close-2 {
  animation: close-C 0.5s linear forwards, shake-C 0.3s linear forwards 0.5s; }

@keyframes close-C {
  0% {
    stroke-dasharray: 349.656, 644.812, 24; }
  100% {
    stroke-dasharray: 349.656, 357.656, 24; } }

.close-scale {
  animation: close-scale 0.5s linear forwards; }

@keyframes close-scale {
  0% {
    transform: scale(0.5);
    top: 0;
    left: 0; }
  100% {
    transform: scale(1);
    top: -25px;
    left: -25px; } }

@keyframes shake-A {
  0% {
    transform: translate3d(0, 0, 0); }
  10%, 50%, 90% {
    transform: translate3d(7%, 0, 0); }
  30%, 70% {
    transform: translate3d(-7%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes shake-C {
  0% {
    transform: translate3d(0, 0, 0); }
  10%, 50%, 90% {
    transform: translate3d(-7%, 0, 0); }
  30%, 70% {
    transform: translate3d(7%, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

[class*="btn"] {
  max-width: 300px;
  width: 100%;
  max-height: 50px;
  padding: 3% 5px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%);
  box-shadow: -0.9px 1.8px 4px rgba(58, 41, 0, 0.49);
  color: #1c6352;
  font: normal 700 1.2rem "Rubik", sans-serif;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear; }
  [class*="btn"]:link, [class*="btn"]:hover, [class*="btn"]:active, [class*="btn"]:visited {
    color: #1c6352;
    text-decoration: none; }
  [class*="btn"]:active {
    position: relative;
    top: 1px;
    left: 1px; }

[class*='nav-arrow'] {
  position: relative;
  cursor: pointer; }
  [class*='nav-arrow']:after {
    content: ' ';
    position: absolute;
    top: calc(50% - 3px);
    right: 5px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 9px solid #222222;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent; }

/* ---- RESPONSIVE ELEMENTS ---- */
[class*='pict_responsive'] {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  [class*='pict_responsive'] img {
    display: block;
    box-sizing: border-box; }

[class*="table_responsive"] {
  width: 100%;
  margin: 0 auto; }
  [class*="table_responsive"] thead tr th {
    padding: 3% 3% 0 3%; }
  [class*="table_responsive"] tbody tr td {
    padding: 0 3%; }
  [class*="table_responsive"] td, [class*="table_responsive"] th {
    color: #222222;
    font: normal 400 1.2rem / 100% 'Lato', sans-serif; }
  [class*="table_responsive"] thead tr th {
    color: #222222;
    font-weight: 700; }

/* ---- FORM ELEMENTS ---- */
.g-recaptcha > div {
  margin: 10px auto !important;
  height: 79px !important;
  overflow: hidden;
  border-radius: 10px; }

.rc-anchor {
  width: 100%;
  border-radius: 0 !important; }

.rc-anchor-content {
  width: 100% !important;
  height: 100% !important; }

.rc-anchor-compact-footer {
  display: none; }

.form {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column; }
  .form form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .form_bg {
    padding: 3%;
    background: #ffffff;
    border-radius: 5px; }
  .form__box {
    display: flex;
    align-items: center; }
  .form__group {
    margin: 10px auto;
    display: flex;
    align-items: center;
    position: relative; }
    .form__group:not(.form__group-check):before, .form__group:not(.form__group-check):after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 0;
      transform: scale(1);
      transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
    .form__group:not(.form__group-check):before {
      top: 0;
      border-top: 2px solid #ffffff;
      transform-origin: left; }
    .form__group:not(.form__group-check):after {
      bottom: 0;
      border-bottom: 2px solid #ffffff;
      transform-origin: right; }
    .form__group:not(.form__group-check):hover:before, .form__group:not(.form__group-check).focus:before, .form__group:not(.form__group-check).activate:before {
      animation: border-top 1s linear forwards; }

@keyframes border-top {
  0% {
    transform: scale(1);
    border-top: 2px solid #ffffff; }
  50% {
    transform: scale(0);
    border-top: 2px solid #ffffff; }
  100% {
    transform: scale(1);
    border-top: 2px solid #e4d36c; } }
    .form__group:not(.form__group-check):hover:after, .form__group:not(.form__group-check).focus:after, .form__group:not(.form__group-check).activate:after {
      animation: border-bottom 1s linear forwards; }

@keyframes border-bottom {
  0% {
    transform: scale(1);
    border-bottom: 2px solid #ffffff; }
  50% {
    transform: scale(0);
    border-bottom: 2px solid #e4d36c; }
  100% {
    transform: scale(1);
    border-bottom: 2px solid #e4d36c; } }
    .form__group:not(.form__group-check):hover .label, .form__group:not(.form__group-check).focus .label, .form__group:not(.form__group-check).activate .label {
      color: #e4d36c !important; }
    .form__group:not(.form__group-check).activate .label {
      font-size: 0.75rem !important;
      line-height: 1 !important;
      text-transform: uppercase;
      padding: 0; }
    .form__group-full {
      flex-direction: column;
      align-items: flex-start; }
      .form__group-full .label {
        min-width: auto; }
    .form__group_box {
      width: 100%;
      display: flex; }
    .form__group-file {
      align-items: flex-start; }
      .form__group-file .text-input {
        display: none !important; }
      .form__group-file .label {
        min-width: 160px;
        height: 50px;
        display: flex;
        align-items: center; }
      .form__group-file label {
        display: block;
        width: 100%;
        padding: 0;
        line-height: 30px;
        position: relative; }
      .form__group-file .file-not {
        width: 100% !important;
        min-height: 40px;
        height: auto !important;
        padding: 0 0 0 165px; }
        .form__group-file .file-not[class*="status"]:not([class*="btn"]):before {
          position: absolute;
          left: 5px;
          top: 5px;
          width: 30px;
          height: 30px;
          z-index: 10; }
        .form__group-file .file-not:after {
          content: attr(data-btn);
          position: absolute;
          left: 0;
          top: 0;
          bottom: auto !important;
          display: block;
          width: 150px;
          min-height: 40px;
          padding: 5px;
          margin: auto;
          border: none;
          border-radius: 2px;
          background-image: linear-gradient(to top, #03a5d3 0%, #09c6f5 100%);
          color: #ffffff;
          font: normal 600 1.25rem "Rubik", sans-serif;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
          transition: all 0.2s linear; }
        .form__group-file .file-not:after {
          width: 160px !important;
          padding-left: 40px;
          text-align: left;
          right: 100% !important; }
        .form__group-file .file-not span:not(.file-max) {
          height: 100%;
          line-height: 40px; }
        .form__group-file .file-not .file-max {
          height: 100%;
          line-height: 100%; }
          .form__group-file .file-not .file-max b {
            color: #ff0000; }
        .form__group-file .file-not:hover:after {
          box-shadow: 1px 1px 0px #222222, 1px 1px 0px #222222, 2px 2px 0px #222222, 2px 2px 0px #222222, 3px 3px 0px #222222, 3px 3px 0px #222222, 4px 4px 0px #222222, 5px 5px 0px #222222; }
      .form__group-file .file-not_box, .form__group-file .file_box {
        width: 100%;
        height: auto;
        padding: 5px 0;
        cursor: pointer; }
      .form__group-file .btnDelete {
        display: inline-block;
        max-width: 160px !important;
        height: 40px;
        margin: 5px 0;
        font-size: 1.25rem;
        line-height: 20px; }
  .form .file_preview {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 5px;
    position: relative; }
    .form .file_preview_box {
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .form .file_preview > span {
      width: 100px;
      padding: 5px;
      background: #f38126;
      color: #ffffff;
      font-size: 1rem;
      text-align: center;
      word-break: break-all;
      position: absolute;
      top: 102%;
      left: 0;
      z-index: 100;
      display: none; }
    .form .file_preview:after {
      content: attr(data-del);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.6);
      color: #ffffff;
      font: normal 600 1rem 'Lato', sans-serif;
      text-align: center;
      display: none; }
    .form .file_preview:hover > span, .form .file_preview:hover:after {
      display: flex; }
    .form .file_preview-del {
      border: 2px solid #ff0000; }
      .form .file_preview-del:after {
        display: flex; }
      .form .file_preview-del > span {
        left: -2px; }
  .form .file_image {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: auto; }
  .form .text-input, .form .textarea, .form .select, .form .label, .form .check-label {
    background: transparent;
    color: #222222;
    font: normal 400 1.2rem "Rubik", sans-serif; }
  .form .text-input, .form .textarea, .form .select {
    margin: 10px 0; }
  .form .text-input, .form .textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background: transparent;
    color: #000000; }
  .form .text-input {
    padding: 0 0 0 10px; }
  .form .textarea {
    padding: 10px 0 0 10px;
    resize: none;
    overflow: auto; }
  .form .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 0;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent; }
  .form [class*='check-input_label'] {
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid #e4d36c;
    border-radius: 2px;
    background: linear-gradient(to top, #102c24 0%, #195345 50%, #102c24 100%);
    position: relative;
    cursor: pointer; }
    .form [class*='check-input_label']:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 3px;
      top: 4px;
      width: 14px;
      height: 7px;
      border-left: solid 3px #ffffff;
      border-bottom: solid 3px #ffffff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0; }
  .form .check-input {
    display: none; }
    .form .check-input:checked + [class*='check-input_label']:after {
      opacity: 1; }
    .form .check-input_label-circle {
      min-width: 30px;
      width: 30px;
      height: 30px;
      margin: 5px;
      background: #ffffff;
      border: 1px solid #b3b3b3;
      border-radius: 50%;
      box-shadow: inset -2px 2.2px 3px rgba(0, 0, 0, 0.66); }
      .form .check-input_label-circle:after {
        left: 5px;
        top: 8px;
        width: 16px;
        height: 9px; }
    .form .check-input_label-buton {
      min-width: 40px;
      width: auto;
      height: 40px;
      margin: 0;
      padding: 0 5px;
      border: none;
      background: linear-gradient(to top, #7bb461 0%, #8dd070 100%);
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center; }
      .form .check-input_label-buton:after {
        border: none; }
      .form .check-input_label-buton:hover {
        background: #f38126; }
  .form .check-label {
    color: #222222; }
    .form .check-label a {
      color: #222222 !important; }
      .form .check-label a:hover {
        text-decoration: underline; }
    .form .check-label span {
      color: #f38126; }
  .form .label {
    padding: 0;
    color: #222222;
    font-size: 1.6rem;
    white-space: nowrap; }
    .form .label span {
      color: #f38126; }
    .form .label[for*='message'] {
      align-self: flex-start;
      margin: 10px 0 0 0; }
  .form__recaptcha {
    margin: auto; }
  .form [class*="form__btn"] {
    min-height: 50px;
    padding: 5px;
    text-transform: uppercase; }
  .form__note {
    color: #f38126;
    font: normal 400 1rem "Rubik", sans-serif; }
  .form__tooltip {
    width: 100%;
    display: none;
    padding: 3px 0 0 0;
    color: #e4d36c;
    font-size: 10px;
    text-align: right;
    position: absolute;
    left: 0;
    bottom: -10px; }
  .form__invalid:not(.form__group-check) {
    border-top: 2px solid #ff0000;
    border-bottom: 2px solid #ff0000; }
  .form__invalid:before {
    border-top: 2px solid #ff0000 !important; }
  .form__invalid:after {
    border-bottom: 2px solid #ff0000 !important; }
  .form__invalid-label {
    color: #ff0000 !important; }
  .form__valid:not(.form__group-check) {
    border-top: 2px solid #008000;
    border-bottom: 2px solid #008000; }
  .form__valid:before {
    border-top: 2px solid #008000 !important; }
  .form__valid:after {
    border-bottom: 2px solid #008000 !important; }
  .form__valid-label {
    color: #008000 !important; }
  .form__popUp {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    cursor: pointer; }
    .form__popUp-wrapper {
      width: 100%;
      height: 100%;
      position: relative; }
    .form__popUp-bg {
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    .form__popUp-content {
      width: 300px;
      height: 300px;
      border-radius: 5px;
      background: #ffffff;
      position: absolute;
      top: 45%;
      left: 50%;
      margin-top: -150px;
      margin-left: -150px;
      z-index: 100;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .form__popUp-foto {
      max-width: 300px;
      max-height: 50px; }
      .form__popUp-foto img {
        display: block;
        box-sizing: border-box;
        height: 100%;
        margin: auto; }
    .form__popUp-text {
      padding: 1rem 0;
      color: #555;
      font: normal 400 16px/18px "Rubik", sans-serif;
      text-align: center; }
    .form__popUp-close {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 9000;
      cursor: pointer; }
      .form__popUp-close__section {
        display: flex;
        align-items: center;
        justify-content: center; }
      .form__popUp-close__cross {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        border: solid 2px #1c6352; }
        .form__popUp-close__cross span {
          width: 20px;
          height: 2px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          left: 50%;
          margin-left: -10px; }
          .form__popUp-close__cross span::before, .form__popUp-close__cross span::after {
            content: " ";
            display: block;
            width: 20px;
            height: 2px;
            background: #1c6352;
            position: absolute;
            top: 50%;
            margin-top: -1px;
            left: 50%;
            margin-left: -10px; }
          .form__popUp-close__cross span::before {
            transform: rotate(45deg); }
          .form__popUp-close__cross span::after {
            transform: rotate(-45deg); }
  .form-search {
    max-width: 250px;
    width: 100%;
    height: 40px;
    position: relative; }
    .form-search .label {
      white-space: nowrap; }
    .form-search .search_buton {
      background: none;
      position: absolute;
      top: 0;
      right: 100%;
      height: 40px;
      width: 40px;
      padding: 0;
      border-radius: 100%;
      outline: 0;
      border: 0;
      color: inherit;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      transform: translate(100%, 0%); }
      .form-search .search_buton:before {
        content: "";
        position: absolute;
        top: 30px;
        right: 3px;
        width: 15px;
        height: 4px;
        background-color: #222222;
        transform: rotate(45deg); }
      .form-search .search_buton.close {
        right: 0;
        transform: translate(0%, 0%);
        transition: 0.4s ease-in-out; }
        .form-search .search_buton.close:before {
          width: 27px;
          top: 18px;
          right: 5px; }
        .form-search .search_buton.close:after {
          content: " ";
          position: absolute;
          top: 18px;
          right: 5px;
          width: 27px;
          height: 4px;
          background-color: #222222;
          cursor: pointer;
          transform: rotate(-45deg); }
    .form-search .search-input {
      width: 30px;
      height: 30px;
      border: 4px solid #222222;
      border-radius: 50%;
      background: none;
      color: #222222;
      font: normal 400 1.2rem "Rubik", sans-serif;
      outline: 0;
      transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s; }
      .form-search .search-input::placeholder {
        color: transparent; }
      .form-search .search-input.square {
        padding: 0 40px 0 10px;
        width: 100%;
        height: 40px;
        border: 2px solid #222222;
        border-radius: 0;
        transition: width 0.2s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s;
        transition-delay: 0.4s, 0s, 0.4s; }
        .form-search .search-input.square::placeholder {
          color: #666666; }
    .form-search-icon [class*="form__btn"] {
      display: none;
      max-width: none !important;
      margin: 0 auto;
      margin-top: 10px !important;
      line-height: 100%; }

.password-input {
  display: inline-block;
  width: 100%;
  position: relative; }

.password__buton {
  width: 30px;
  height: 30px;
  background: url("../images/password-show.png") 50% 50%/100% auto no-repeat;
  position: absolute;
  top: calc(50% - 15px);
  right: 5px;
  cursor: pointer; }

.password-show {
  background: url("../images/password-hide.png") 50% 50%/100% auto no-repeat; }

.birthdate__box {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end; }

.birthdate__month {
  flex: 1 1 auto; }
  .birthdate__month .selectator_element {
    max-width: 120px;
    float: right; }

.birthdate__data {
  width: 40px; }
  .birthdate__data .text-input {
    padding: 5px 0 0 5px; }

.birthdate__year {
  width: 60px; }

.birthdate .label {
  min-width: auto !important; }

.birthdate .form__tooltip {
  width: 100%;
  position: absolute;
  bottom: -10px;
  left: 0; }

.pay-check {
  display: inline-block;
  width: 0px;
  height: 0px;
  position: relative; }
  .pay-check:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 20px;
    height: 10px;
    border-left: solid 3px #008000;
    border-bottom: solid 3px #008000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }

/* ---- SERVICE CLASS---- */
[class*="-nowrap"] {
  flex-wrap: nowrap; }

[class*="-stretch"] {
  flex: 1 1 100%; }

.hidden {
  display: none !important; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column; } }

/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header {
  width: 100%;
  min-width: 320px; }

.header__top {
  padding: 2% 10% 3% 10%;
  background: linear-gradient(to right, #2e2d2d 10%, black 43%, #0d0a09 50%, #0d0b0b 57%, #2e2d2d 90%); }

.header__content {
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  position: relative; }

.header, .footer {
  position: relative; }
  .header__top, .footer__top {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between; }

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer {
  width: 100%;
  min-width: 320px;
  order: 3; }

.footer__top {
  padding: 2% 10%; }

.footer p {
  text-align: center;
  margin: 0 0 2% 0; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
main {
  width: 100%;
  min-width: 320px; }

[class*='content-left'] .content__box {
  padding: 0 10% 0 0; }

[class*='content-left'] .content-foto {
  justify-content: flex-start; }

[class*='content-right'] .content__box {
  padding: 0 0 0 10%; }

[class*='content-right'] .content-foto {
  justify-content: flex-end; }

.content {
  width: 100%; }
  .content section {
    padding: 5%;
    position: relative;
    overflow: hidden; }
    .content section:not(.slider-services) > .title, .content section:not(.slider-services) > .title-invert {
      margin: 0 0 5% 0;
      position: relative;
      z-index: 1; }
    .content section.content__contacts {
      padding: 5% 0 15px 0; }
  .content__box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; }
  .content__item {
    display: inline-block;
    padding: 2%;
    margin: 1rem;
    border: 1px solid #666666; }
    .content__item-icon {
      height: 65px;
      margin: 20px 0 0 0;
      position: relative; }
      .content__item-icon:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 3px;
        background: #1c6352;
        transform: scale(0.5);
        transform-origin: center center;
        transition: all 0.4s linear; }
      .content__item-icon img {
        display: block;
        box-sizing: border-box;
        height: 100%;
        margin: auto; }
    .content__item:hover {
      border: 1px solid #1c6352;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5); }
  .content__service .content__box {
    margin: 2%; }
  .content__service .content__item {
    width: 300px;
    height: 300px;
    margin: 0;
    background: #fffee0; }
    .content__service .content__item:hover .content__item-icon:after {
      transform: scale(1); }
    .content__service .content__item h3 {
      margin: 40px 0 0 0;
      text-align: center; }
  .content__howWeWork .content__item {
    width: 220px;
    height: 220px;
    padding: 1%;
    border: none;
    border-bottom: 1px solid #666666;
    border-right: 1px solid #666666;
    position: relative; }
    .content__howWeWork .content__item:after {
      content: attr(data-number);
      position: absolute;
      top: -30px;
      left: -5px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%);
      font: normal 700 70px/120% "TrajanPro"; }
    .content__howWeWork .content__item:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 180px;
      border-left: 1px solid #666666; }
    .content__howWeWork .content__item h3 {
      text-align: center;
      padding: 0 0 0 25px; }
    .content__howWeWork .content__item h3, .content__howWeWork .content__item p {
      color: #ffffff; }
    .content__howWeWork .content__item span.border {
      width: 180px;
      height: 1px;
      background: #666666;
      position: absolute;
      top: 0;
      right: 0; }
  .content__howWeWork_row {
    display: flex; }
  .content__comment .content__box {
    align-items: stretch;
    padding: 0 0 60px 0; }
  .content__comment .content__item {
    width: 30%;
    height: auto;
    padding: 30px 1rem 1rem 1rem;
    position: relative; }
    .content__comment .content__item:before {
      content: '';
      position: absolute;
      top: -30px;
      left: 30px;
      min-width: 40px;
      width: 12%;
      max-height: 60px;
      height: 100%;
      background: url("../images/comment.png") center center/contain no-repeat; }
    .content__comment .content__item span {
      position: absolute;
      top: 0;
      right: 30px; }
    .content__comment .content__item h3 {
      position: absolute;
      top: -30px;
      right: 30px; }
  .content__contacts > .content__box {
    align-items: stretch; }
  .content__contacts [class*='btn'] {
    margin: 0; }
  .content__contacts [class*='content-left'] {
    padding: 0 0 0 5%;
    background: linear-gradient(to right, #102c24 0%, #195345 100%); }
    .content__contacts [class*='content-left'] .content__box {
      padding: 1% 5% 1% 1%; }
    .content__contacts [class*='content-left'] .content-foto {
      max-height: 300px; }
      .content__contacts [class*='content-left'] .content-foto img {
        width: auto;
        height: 100%; }
    .content__contacts [class*='content-left'] .content-name {
      margin: 0 0 5% 0; }
      .content__contacts [class*='content-left'] .content-name h4, .content__contacts [class*='content-left'] .content-name p {
        text-align: right; }
    .content__contacts [class*='content-left'] [class*='btn'] {
      align-self: flex-end; }
  .content__contacts [class*='content-right'] {
    padding: 0 5% 0 0;
    background: linear-gradient(to right, #2b302f 0%, #1d2321 100%); }
    .content__contacts [class*='content-right'] .content__box {
      padding: 1% 1% 1% 5%; }
    .content__contacts [class*='content-right'] .content-foto {
      max-height: 300px; }
      .content__contacts [class*='content-right'] .content-foto img {
        width: auto;
        height: 100%; }
    .content__contacts [class*='content-right'] .content-name {
      margin: 0 0 5% 0; }
      .content__contacts [class*='content-right'] .content-name h4, .content__contacts [class*='content-right'] .content-name p {
        text-align: left; }

[class*='content-left'], [class*='content-right'] {
  flex: 1 1 50%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1; }
  [class*='content-left'] .content__box, [class*='content-right'] .content__box {
    max-width: 580px;
    min-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  [class*='content-left'] .content-foto, [class*='content-right'] .content-foto {
    flex: 0 1 auto;
    display: flex;
    align-items: flex-end; }
    [class*='content-left'] .content-foto img, [class*='content-right'] .content-foto img {
      display: block;
      box-sizing: border-box;
      width: 100%; }
  [class*='content-left'] .content-name, [class*='content-right'] .content-name {
    margin: 0 0 15% 0; }
    [class*='content-left'] .content-name h4, [class*='content-right'] .content-name h4 {
      padding: 0;
      color: #ffffff;
      font: normal 500 1.5rem/130% "TrajanPro";
      text-align: center;
      text-shadow: 1px 1px 1px #e4d36c; }
    [class*='content-left'] .content-name p, [class*='content-right'] .content-name p {
      padding: 2.5% 0;
      color: #ffffff;
      font: normal 400 1.2rem/120% "Rubik", sans-serif;
      text-align: center; }

.map {
  width: 100%;
  padding: 0 !important;
  background: none !important;
  display: flex;
  justify-content: center;
  align-self: stretch; }
  .map h3 {
    color: #222222;
    font: normal 700 1.6rem/100% "TrajanPro";
    text-align: center; }
  .map a {
    color: #0099cc;
    font: italic 400 1.2rem/100% "Rubik", sans-serif; }

[class*='slider'] ul {
  list-style: none; }

.slider-service {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .slider-service ul {
    list-style: none; }
  .slider-service .slider-target {
    display: none; }
    .slider-service .slider-target li {
      max-width: 65px;
      width: 65px;
      max-height: 65px;
      height: 65px;
      flex: auto;
      border: 1px solid #666666;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
      .slider-service .slider-target li:hover, .slider-service .slider-target li.slider-target-current {
        background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%); }
    .slider-service .slider-target img {
      display: block;
      box-sizing: border-box;
      width: auto;
      height: 50px;
      object-fit: cover;
      object-position: top left; }
  .slider-service .slider-item {
    width: 100%;
    height: auto;
    overflow: hidden; }
    .slider-service .slider-item li {
      margin: 1rem; }

.canvas {
  box-sizing: border-box;
  width: 100%; }

/* ---- SERVICES, PRACTICE AND ARTICLE PAGE---- */
.services .form-main, .practice .form-main, .article .form-main {
  background: url("../images/bg-howWeWork.jpg") 50% 50%/cover no-repeat; }

.services .form__box, .practice .form__box, .article .form__box {
  align-items: stretch; }

.services .form__group, .practice .form__group, .article .form__group {
  flex: 1 1 100%;
  margin: 1rem; }
  .services .form__group-check .label, .practice .form__group-check .label, .article .form__group-check .label {
    min-width: 360px; }

.services .form .text-input, .services .form .textarea, .services .form .select, .services .form .label, .services .form .check-label, .practice .form .text-input, .practice .form .textarea, .practice .form .select, .practice .form .label, .practice .form .check-label, .article .form .text-input, .article .form .textarea, .article .form .select, .article .form .label, .article .form .check-label {
  border: none;
  color: #ffffff; }

.services .form__btn, .practice .form__btn, .article .form__btn {
  margin: 20px auto; }

/* ---- SERVICES PAGE---- */
.slider-services {
  display: flex;
  position: relative; }
  .slider-services .title {
    position: absolute;
    top: 10%;
    left: 5%; }
  .slider-services .slider-target {
    max-width: 380px;
    min-width: 380px;
    padding: 1%;
    border: 1px solid #666666; }
    .slider-services .slider-target li {
      width: 100%;
      height: auto;
      margin: 10px 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer; }
      .slider-services .slider-target li:hover span:last-of-type, .slider-services .slider-target li.slider-target-current span:last-of-type {
        font-weight: 700; }
        .slider-services .slider-target li:hover span:last-of-type:after, .slider-services .slider-target li.slider-target-current span:last-of-type:after {
          width: 100%;
          height: 3px; }
    .slider-services .slider-target-icon {
      min-width: 70px; }
    .slider-services .slider-target img {
      display: block;
      box-sizing: border-box;
      width: auto;
      height: 50px;
      object-fit: cover;
      object-position: top left; }
    .slider-services .slider-target span:last-of-type {
      display: inline-block;
      position: relative; }
      .slider-services .slider-target span:last-of-type:after {
        content: '';
        position: absolute;
        left: 0px;
        bottom: -8px;
        width: 40px;
        height: 1px;
        background: #1c6352;
        transform-origin: left center;
        transition: all 0.4s linear; }
  .slider-services .slider-item {
    padding: 0 3%;
    overflow: hidden;
    position: relative; }
    .slider-services .slider-item li {
      margin: 0;
      background: #fffee0;
      align-self: flex-start;
      position: absolute;
      top: 7%;
      left: 1%;
      z-index: 1;
      opacity: 0; }
      .slider-services .slider-item li.slider-item-target {
        width: 100%;
        position: relative;
        z-index: 3;
        animation: slide-services 1s 1;
        opacity: 1; }
    .slider-services .slider-item h3 {
      padding: 5px 0 5px 30px; }
    .slider-services .slider-item p {
      padding: 5px 0 5px 30px;
      white-space: pre-wrap;
      position: relative; }
      .slider-services .slider-item p:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 0px;
        bottom: 50%;
        width: 20px;
        height: 10px;
        border-left: solid 3px #1c6352;
        border-bottom: solid 3px #1c6352;
        transform: rotate(-45deg); }

@keyframes slide-services {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0%);
    opacity: 1; } }
    .slider-services .slider-item:not(.slider-item-target) {
      animation: hidden-services 1s 1; }

@keyframes hidden-services {
  0% {
    z-index: 2;
    opacity: 0; }
  100% {
    z-index: 2;
    opacity: 0; } }

/* ---- PRACTICE PAGE---- */
.practice > .content__box {
  align-items: stretch; }

.practice__column {
  flex: 1 1 50%;
  padding: 1rem; }

.practice__item {
  padding: 0 0 40px 0;
  position: relative; }
  .practice__item:before {
    width: 0; }
  .practice__item:not(:last-child):before, .practice__item:not(:last-child):after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    transform: scale(0.5);
    transform-origin: center top;
    transition: all 0.4s linear; }
  .practice__item:not(:last-child):before {
    top: auto;
    bottom: 7px;
    z-index: 1; }
  .practice__item:not(:last-child):after {
    height: 3px;
    background-color: #1c6352;
    bottom: 20px;
    z-index: 2; }
  .practice__item:not(:last-child):hover:before, .practice__item:not(:last-child):hover:after {
    transform: scale(1); }
  .practice__item p {
    margin: 10px 0; }
  .practice__item a {
    color: #0099cc; }
    .practice__item a:link, .practice__item a:active, .practice__item a:visited {
      color: #0099cc; }

/* ---- ARTICLE PAGE---- */
.article__item {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 40px 10px;
  position: relative; }
  .article__item:before {
    width: 0; }
  .article__item:not(:last-child):before, .article__item:not(:last-child):after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    transform: scale(0.5);
    transform-origin: center top;
    transition: all 0.4s linear; }
  .article__item:not(:last-child):before {
    top: auto;
    bottom: 7px;
    z-index: 1; }
  .article__item:not(:last-child):after {
    height: 3px;
    background: #e4d36c;
    bottom: 20px;
    z-index: 2; }
  .article__item:not(:last-child):hover:before, .article__item:not(:last-child):hover:after {
    transform: scale(1); }
  .article__item_pict {
    min-width: 270px;
    width: 270px;
    margin: 0 10px 10px 0;
    display: inline;
    float: left; }
    .article__item_pict img {
      display: block;
      box-sizing: border-box;
      width: 100%; }
  .article__item_text {
    display: inline;
    margin: 0 10px 10px 0; }
  .article__item_date {
    position: absolute;
    top: 10px;
    right: 1rem; }
    .article__item_date span {
      padding: 0 0 0 40px;
      color: #666666;
      position: relative; }
      .article__item_date span:after {
        content: url("../images/svg-calendar.svg");
        position: absolute;
        top: 0;
        left: 0;
        width: 30px; }
  .article__item p {
    margin: 10px 0; }
  .article__item a {
    color: #0099cc; }
    .article__item a:link, .article__item a:active, .article__item a:visited {
      color: #0099cc; }

/* ---- CONTACTS PAGE---- */
.contacts section {
  padding: 5% 0 0 0 !important; }
  .contacts section > .content__box {
    align-items: stretch; }
    .contacts section > .content__box:nth-of-type(1) {
      background: linear-gradient(to right, #102c24 0%, #195345 100%); }
    .contacts section > .content__box:nth-of-type(3) {
      background: linear-gradient(to right, #2b302f 0%, #1d2321 100%); }

.contacts [class*='content-left'] .content__box, .contacts [class*='content-right'] .content__box {
  justify-content: flex-start; }

.contacts [class*='content-left'] .content-name, .contacts [class*='content-right'] .content-name {
  margin: 0; }

.contacts [class*='content-left'].form-contacts, .contacts [class*='content-right'].form-contacts {
  align-items: flex-start; }

.contacts [class*='content-left'] [class*='btn'], .contacts [class*='content-right'] [class*='btn'] {
  margin: 0; }

.contacts [class*='content-left'] {
  padding: 0 0 0 5%; }
  .contacts [class*='content-left'] .content__box {
    padding: 2rem 1rem 2rem 1%; }
  .contacts [class*='content-left'].form-contacts {
    padding: 2rem 1rem 2rem 5%;
    justify-content: flex-end; }
  .contacts [class*='content-left'] [class*='btn'] {
    align-self: flex-end; }
  .contacts [class*='content-left'] h4, .contacts [class*='content-left'] p {
    text-align: right; }

.contacts [class*='content-right'] {
  padding: 0 5% 0 0; }
  .contacts [class*='content-right'] .content__box {
    padding: 2rem 1% 2rem 1rem; }
  .contacts [class*='content-right'].form-contacts {
    padding: 2rem 5% 2rem 1rem;
    justify-content: flex-start; }
  .contacts [class*='content-right'] h4, .contacts [class*='content-right'] p {
    text-align: left; }

.contacts .map {
  height: 450px; }

.contacts .form {
  max-width: 600px;
  margin: 0; }
  .contacts .form-contacts h4 {
    padding: 0;
    color: #ffffff;
    font: normal 500 1.5rem/130% "TrajanPro";
    text-shadow: 1px 1px 1px #e4d36c; }
  .contacts .form__box {
    flex-direction: column;
    align-items: stretch; }
  .contacts .form__group {
    flex: 1 1 100%;
    margin: 1rem 0; }
    .contacts .form__group-check .check-input_label {
      order: 1; }
    .contacts .form__group-check .label {
      min-width: auto;
      order: 2; }
  .contacts .form .label {
    min-width: 170px; }
  .contacts .form .text-input, .contacts .form .textarea, .contacts .form .select, .contacts .form .label, .contacts .form .check-label {
    border: none;
    color: #ffffff; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column; } }

@media screen and (max-width: 1600px) {
  html {
    font-size: 15px; }
  .header__top {
    padding: 2% 3% 3% 3%; }
  .header__content {
    padding: 0 2%; }
  .footer__top {
    padding: 2% 3%; }
  [class*='content-left'] .content__box, [class*='content-right'] .content__box {
    min-width: 410px; }
  [class*='content-left'] .content-name, [class*='content-right'] .content-name {
    margin: 0 0 5% 0; }
    [class*='content-left'] .content-name h4, [class*='content-right'] .content-name h4 {
      line-height: 100%; }
    [class*='content-left'] .content-name p, [class*='content-right'] .content-name p {
      padding: 1.0% 0; }
  [class*='content-left'] .content__box {
    padding: 0 4% 0 0; }
  [class*='content-right'] .content__box {
    padding: 0 0 0 4%; }
  [class*="nav"] ul li {
    margin: 0 3%; }
  .content section {
    padding: 3%; }
    .content section > .title, .content section > .title-invert {
      margin: 0 0 3% 0; }
  .content__item {
    padding: 2%;
    margin: 1%; }
  .content__service .content__box {
    margin: 0; }
  .content__service .content__item {
    width: 270px;
    height: 270px;
    margin: 0; }
  .content__service .slider-item li {
    margin: 1%; }
  .content__contacts {
    padding: 2% 0 0 0 !important; }
    .content__contacts [class*='content-left'] {
      padding: 0 0 0 2%; }
      .content__contacts [class*='content-left'] .content__box {
        padding: 1% 3% 1% 1%; }
    .content__contacts [class*='content-right'] {
      padding: 0 2% 0 0; }
      .content__contacts [class*='content-right'] .content__box {
        padding: 1% 1% 1% 3%; }
  .content__comment .slick-dots {
    bottom: 35px; }
  .content__comment .slick-prev,
  .content__comment .slick-next {
    top: auto;
    bottom: -10px; }
  .content__comment .slick-prev:before,
  .content__comment .slick-next:before {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent; }
  .content__comment .slick-prev {
    left: calc(50% - 150px); }
  .content__comment [dir='rtl'] .slick-prev {
    right: calc(50% - 150px); }
  .content__comment .slick-next {
    right: calc(50% - 150px); }
  .content__comment [dir='rtl'] .slick-next {
    left: calc(50% - 150px); }
  /* ---- SERVICES PAGE---- */
  .slider-services .title {
    padding: 0 0 0 380px;
    top: 5%; }
  /* ---- CONTACTS PAGE---- */
  .contacts section {
    padding: 2% 0 0 0 !important; }
  .contacts .content__box {
    min-width: auto; }
  .contacts [class*='content-left'], .contacts [class*='content-right'] {
    flex: 1 1 60%; }
  .contacts [class*='content-left'] {
    padding: 0 0 0 2%; }
    .contacts [class*='content-left'].form-contacts {
      flex: 1 1 40%;
      padding: 2rem 1rem 2rem 2%; }
  .contacts [class*='content-right'] {
    padding: 0 2% 0 0; }
    .contacts [class*='content-right'].form-contacts {
      flex: 1 1 40%;
      padding: 2rem 2% 2rem 1rem; } }

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px; }
  .header__content {
    padding: 0; }
    .header__content [class*='content-left'], .header__content [class*='content-right'] {
      flex-direction: column; }
      .header__content [class*='content-left'] .content__box, .header__content [class*='content-right'] .content__box {
        min-width: auto;
        padding-bottom: 5%;
        justify-content: space-between;
        order: 1; }
      .header__content [class*='content-left'] .content-foto, .header__content [class*='content-right'] .content-foto {
        order: 2;
        justify-content: center; }
        .header__content [class*='content-left'] .content-foto img, .header__content [class*='content-right'] .content-foto img {
          width: auto;
          max-height: 400px;
          height: 100%;
          margin: auto; }
      .header__content [class*='content-left'] .content-name, .header__content [class*='content-right'] .content-name {
        margin: 3% 0; }
  .content section {
    padding: 10px 5px; }
    .content section.slider-services {
      padding: 3%; }
  .content__service .content__item {
    width: 215px;
    height: 215px;
    padding: 5px; }
    .content__service .content__item-icon {
      margin: 15px 0 0 0; }
      .content__service .content__item-icon:after {
        bottom: -15px; }
    .content__service .content__item h3 {
      margin: 30px 0 0 0; }
  .content__contacts [class*='content-left'], .content__contacts [class*='content-right'] {
    flex: 1 1 100%;
    order: 1; }
    .content__contacts [class*='content-left'] .content-foto img, .content__contacts [class*='content-right'] .content-foto img {
      width: auto;
      height: 100%; }
  .content__contacts .map {
    height: 350px;
    order: 2 !important; }
  .content__howWeWork .content__item {
    margin: 10px;
    display: inline-block; }
  .canvas {
    display: none; }
  /* ---- SERVICES, PRACTICE AND ARTICLE PAGE---- */
  .services .form, .practice .form, .article .form {
    max-width: 600px;
    margin: auto; }
    .services .form__box, .practice .form__box, .article .form__box {
      flex-direction: column; }
    .services .form__group-check .check-input_label, .practice .form__group-check .check-input_label, .article .form__group-check .check-input_label {
      order: 1; }
    .services .form__group-check .label, .practice .form__group-check .label, .article .form__group-check .label {
      min-width: auto;
      order: 2; }
    .services .form .label, .practice .form .label, .article .form .label {
      min-width: 130px; }
  /* ---- SERVICES PAGE ---- */
  .slider-services .title {
    padding: 0 0 0 300px;
    left: 3%; }
  .slider-services .slider-target {
    max-width: 300px;
    min-width: 300px; }
    .slider-services .slider-target li {
      margin: 5px 0; }
      .slider-services .slider-target li span:last-of-type:after {
        bottom: -5px; }
    .slider-services .slider-target-icon {
      min-width: 50px; }
    .slider-services .slider-target img {
      height: 30px; } }

@media screen and (max-width: 991px) {
  html {
    font-size: 13px; }
  [class*="nav"] {
    width: 100%; }
    [class*="nav"] ul li {
      margin: 0 2%; }
  [class*="btn"] {
    max-width: 200px; }
  [class*='content-left'] .content__box, [class*='content-right'] .content__box {
    max-width: none;
    min-width: auto; }
  [class*='content-left'] .content-foto, [class*='content-right'] .content-foto {
    max-width: 170px; }
  .header__top, .footer__top {
    flex-direction: column; }
  .header__top {
    padding: 2% 1% 20px 1%; }
  .header__content [class*='content-left'], .header__content [class*='content-right'] {
    flex-direction: row;
    padding: 1%; }
    .header__content [class*='content-left'] .content-foto, .header__content [class*='content-right'] .content-foto {
      min-width: 100px;
      width: 100px;
      min-height: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      padding: 1%;
      background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%); }
      .header__content [class*='content-left'] .content-foto img, .header__content [class*='content-right'] .content-foto img {
        width: 120px;
        max-height: none;
        height: auto; }
  .header__content [class*='content-left'] .content__box {
    order: 2; }
  .header__content [class*='content-left'] .content-foto {
    order: 1; }
  .header__content [class*='content-right'] .content__box {
    order: 1; }
  .header__content [class*='content-right'] .content-foto {
    order: 2; }
  .footer__top {
    padding: 2% 1%; }
  .content__item {
    margin: 5px; }
  .content__comment .content__item:before {
    left: 10px; }
  .content__comment .content__item span {
    right: 10px; }
  .content__comment .content__item h3 {
    right: 10px; }
  .content__howWeWork .content__item {
    width: 200px;
    height: 200px;
    margin: 15px; }
    .content__howWeWork .content__item:before {
      height: 160px; }
    .content__howWeWork .content__item span.border {
      width: 160px; }
  /* ---- PRACTICE PAGE---- */
  .practice > .content__box {
    align-items: stretch; }
  .practice__column {
    flex: 1 1 100%;
    padding: 1rem; }
    .practice__column:not(:last-child) .practice__item:last-child:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      margin: auto;
      background-color: #1c6352;
      position: absolute;
      bottom: 20px;
      left: 0;
      z-index: 1;
      transform: scale(0.5);
      transform-origin: center;
      transition: all 0.4s linear; }
    .practice__column:not(:last-child) .practice__item:last-child:hover:after {
      transform: scale(1); }
  /* ---- CONTACTS PAGE---- */
  .contacts [class*='content-left'], .contacts [class*='content-right'] {
    flex: 1 1 45%; }
    .contacts [class*='content-left'] .content-foto, .contacts [class*='content-right'] .content-foto {
      min-width: 100px;
      width: 100px;
      min-height: 100px;
      height: 100px;
      margin: 2rem 1rem;
      border-radius: 50%;
      overflow: hidden;
      padding: 1%;
      background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%);
      align-items: flex-start; }
      .contacts [class*='content-left'] .content-foto img, .contacts [class*='content-right'] .content-foto img {
        width: 120px;
        max-height: none;
        height: auto; }
  .contacts [class*='content-left'].form-contacts {
    flex: 1 1 45%; }
  .contacts [class*='content-right'].form-contacts {
    flex: 1 1 45%; }
  /* ---- CONTACTS PAGE---- */
  .contacts section > .content__box {
    flex-direction: column; }
  .contacts [class*='content-left'], .contacts [class*='content-right'] {
    padding: 10px 5px;
    order: 1; }
    .contacts [class*='content-left'].form-contacts, .contacts [class*='content-right'].form-contacts {
      padding: 10px 0;
      order: 2; }
      .contacts [class*='content-left'].form-contacts h4, .contacts [class*='content-right'].form-contacts h4 {
        text-align: center; }
    .contacts [class*='content-left'] .content__box, .contacts [class*='content-left'] .content-name, .contacts [class*='content-right'] .content__box, .contacts [class*='content-right'] .content-name {
      width: 100%;
      padding: 0; }
    .contacts [class*='content-left'] .content-foto, .contacts [class*='content-right'] .content-foto {
      margin: 0;
      position: absolute;
      top: 10px;
      left: 5px;
      right: auto;
      bottom: auto; }
    .contacts [class*='content-left'] .content-name, .contacts [class*='content-right'] .content-name {
      max-width: 600px;
      margin: auto;
      padding: 0 5px; }
      .contacts [class*='content-left'] .content-name h4, .contacts [class*='content-left'] .content-name p, .contacts [class*='content-right'] .content-name h4, .contacts [class*='content-right'] .content-name p {
        text-align: left !important;
        padding: 0 0 0 100px; }
      .contacts [class*='content-left'] .content-name h4, .contacts [class*='content-right'] .content-name h4 {
        padding-bottom: 1rem; }
  .contacts .form {
    margin: auto;
    padding: 0 10px; }
  .contacts [class*='btn'] {
    align-self: center !important; }
  .contacts .map {
    height: 250px; } }

@media screen and (max-width: 700px) {
  .hamburger-box {
    display: block; }
  [class*="nav"] ul {
    flex-direction: column;
    align-items: flex-start; }
    [class*="nav"] ul li {
      display: block;
      margin: 3%; }
  .nav-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 50%;
    height: 100vh;
    background: linear-gradient(to right, #2e2d2d 10%, black 43%, #0d0a09 50%, #0d0b0b 57%, #2e2d2d 90%);
    transition: 0.5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg); }
  .nav-footer {
    display: none; }
  .header__content {
    flex-direction: column; }
    .header__content [class*='content-left'] .content__box {
      padding: 0 10px 0 100px; }
    .header__content [class*='content-left'] .content-foto {
      top: 20px;
      left: 10px; }
    .header__content [class*='content-left'] .content-name {
      align-self: flex-end; }
      .header__content [class*='content-left'] .content-name h4, .header__content [class*='content-left'] .content-name p {
        text-align: right; }
    .header__content [class*='content-left'] [class*='btn'] {
      align-self: flex-end; }
    .header__content [class*='content-right'] .content__box {
      padding: 0 100px 0 10px; }
    .header__content [class*='content-right'] .content-foto {
      bottom: 20px;
      right: 10px; }
    .header__content [class*='content-right'] .content-name {
      align-self: flex-start; }
      .header__content [class*='content-right'] .content-name h4, .header__content [class*='content-right'] .content-name p {
        text-align: left; }
    .header__content [class*='content-right'] [class*='btn'] {
      align-self: flex-start; }
    .header__content [class*='content-left'] .content__box, .header__content [class*='content-right'] .content__box {
      width: 100%;
      padding-bottom: 10px;
      justify-content: center; }
    .header__content [class*='content-left'] .content-foto, .header__content [class*='content-right'] .content-foto {
      position: absolute; }
    .header__content [class*='content-left'] .content-name, .header__content [class*='content-right'] .content-name {
      max-width: 70%; }
    .header__content [class*='content-left'] [class*='btn'], .header__content [class*='content-right'] [class*='btn'] {
      margin: 0; }
  .header [class*="content-bg"] img {
    width: 100%;
    transform: rotate(90deg) scale(3); }
  .footer__top {
    width: auto;
    display: inline-block; }
  .footer p {
    display: inline-block;
    width: calc(100% - 120px); }
    .footer p span {
      display: block; }
  .content__service .content__item {
    width: 100%;
    height: 100%; }
    .content__service .content__item-icon {
      margin: 15px 0 0 0; }
      .content__service .content__item-icon:after {
        bottom: -15px; }
    .content__service .content__item h3 {
      margin: 30px 0 0 0; }
  .content__service .slider-target {
    width: 260px;
    display: flex;
    flex-wrap: wrap; }
  .content__service .slider-item {
    min-width: 195px;
    width: 195px;
    min-height: 195px;
    height: 100%; }
    .content__service .slider-item li {
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1; }
      .content__service .slider-item li .content__item {
        display: block;
        position: relative; }
        .content__service .slider-item li .content__item:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fffee0; }
    .content__service .slider-item-target {
      z-index: 3 !important;
      animation: slide 1s 1; }
      .content__service .slider-item-target .content__item {
        background: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%); }
        .content__service .slider-item-target .content__item:after {
          display: none; }
  @keyframes slide {
    0% {
      transform: translateY(100%); }
    100% {
      transform: translateY(0%); } }
    .content__service .slider-item:not(.slider-item-target) {
      animation: hidden 1s 1; }
  @keyframes hidden {
    0% {
      z-index: 2; }
    100% {
      z-index: 2; } }
  .content .contacts .map {
    height: 200px; }
  .content__comment [class*='title'] {
    margin: 0 0 30px 0 !important; }
  .content__comment .content__box {
    align-items: center; }
  .content__comment .content__item {
    width: 100%;
    height: auto;
    margin: 0 20px;
    padding: 30px 0;
    border: none; }
    .content__comment .content__item:before {
      width: 40px; }
    .content__comment .content__item:after {
      content: '';
      position: absolute;
      top: 5px;
      right: 10px;
      width: calc(100% - 70px);
      height: 1px;
      background: #666666; }
    .content__comment .content__item:hover {
      border: none;
      box-shadow: none; }
    .content__comment .content__item span {
      top: 7px; }
    .content__comment .content__item h3 {
      top: -17px; }
  .content__howWeWork .content__item {
    width: 100%;
    height: auto;
    padding: 0 0 2% 40px;
    margin: 10px;
    border-bottom: 1px solid #666666;
    border-right: none; }
    .content__howWeWork .content__item:after {
      top: -15px; }
    .content__howWeWork .content__item:before {
      display: none; }
    .content__howWeWork .content__item h3 {
      padding: 0; }
    .content__howWeWork .content__item span.border {
      display: none; }
  .content__howWeWork_row {
    flex-wrap: wrap;
    justify-content: center; }
    .content__howWeWork_row:last-of-type .content__item:last-of-type {
      border-bottom: none; }
  /* ---- SERVICES PAGE---- */
  .slider-services {
    flex-direction: column; }
    .slider-services [class*='title'] {
      padding: 0;
      position: static;
      order: 1; }
    .slider-services .slider-target {
      order: 3;
      max-width: none;
      min-width: auto; }
    .slider-services .slider-item {
      order: 2; }
      .slider-services .slider-item li {
        top: 0; }
  /* ---- ARTICLE PAGE---- */
  .article__item_pict {
    min-width: 200px;
    width: 200px; }
  .article__item_date {
    right: 1rem; }
    .article__item_date span {
      padding: 0 0 0 30px; }
      .article__item_date span:after {
        width: 20px; } }

@media screen and (max-width: 480px) {
  .title, .title-invert {
    font-size: 24px; }
  .nav-header {
    width: 100%; }
  .header__content [class*='btn'] {
    margin: 0; }
  .header__content [class*='content-left'] .content__box {
    padding: 1% 5px 1% 110px; }
  .header__content [class*='content-left'] .content-foto {
    top: 10%;
    bottom: auto; }
  .header__content [class*='content-right'] .content__box {
    padding: 1% 110px 1% 5px; }
  .header__content [class*='content-right'] .content-foto {
    top: 20%;
    bottom: auto; }
  .header__content [class*='content-left'] .content-name, .header__content [class*='content-right'] .content-name {
    max-width: none;
    width: 100%; }
    .header__content [class*='content-left'] .content-name h4, .header__content [class*='content-right'] .content-name h4 {
      text-align: center; }
    .header__content [class*='content-left'] .content-name p, .header__content [class*='content-right'] .content-name p {
      text-align: left; }
  .content__service .content__item-icon {
    margin: 20px 0 0 0; }
    .content__service .content__item-icon:after {
      bottom: -20px; }
  .content__service .content__item h3 {
    margin: 40px 0 0 0; }
  .content__service .slider-service {
    flex-direction: column; }
  .content__service .slider-item {
    min-width: 260px;
    width: 260px;
    min-height: 260px; }
  .content__contacts [class*='content-left'] .content__box, .content__contacts [class*='content-right'] .content__box {
    width: 100%; }
  .content__contacts [class*='content-left'] .content-foto, .content__contacts [class*='content-right'] .content-foto {
    display: none; }
  .content__contacts [class*='content-left'] .content-name, .content__contacts [class*='content-right'] .content-name {
    width: 100%; }
    .content__contacts [class*='content-left'] .content-name h4, .content__contacts [class*='content-right'] .content-name h4 {
      text-align: center; }
    .content__contacts [class*='content-left'] .content-name p, .content__contacts [class*='content-right'] .content-name p {
      text-align: left; }
  .content__contacts [class*='content-left'] [class*='btn'], .content__contacts [class*='content-right'] [class*='btn'] {
    align-self: center; }
  /* ---- SERVICES, PRACTICE, ARTICLE AND CONTACTS PAGE---- */
  .services .form__group:not(.form__group-check), .practice .form__group:not(.form__group-check), .article .form__group:not(.form__group-check), .contacts .form__group:not(.form__group-check) {
    flex-direction: column;
    align-items: flex-start; }
    .services .form__group:not(.form__group-check).activate .label, .practice .form__group:not(.form__group-check).activate .label, .article .form__group:not(.form__group-check).activate .label, .contacts .form__group:not(.form__group-check).activate .label {
      padding: 5px 0 0 0; }
  .services .form__group-check .label, .practice .form__group-check .label, .article .form__group-check .label, .contacts .form__group-check .label {
    font-size: 1.2rem;
    white-space: pre-wrap; }
  .services .form .text-input, .practice .form .text-input, .article .form .text-input, .contacts .form .text-input {
    padding: 0; }
  .services .form .textarea, .practice .form .textarea, .article .form .textarea, .contacts .form .textarea {
    padding: 10px 0 0 0; }
  .services .form .selectator_element.single, .practice .form .selectator_element.single, .article .form .selectator_element.single, .contacts .form .selectator_element.single {
    padding: 0 20px 0 0 !important; }
  .services .form .label, .practice .form .label, .article .form .label, .contacts .form .label {
    min-width: auto; }
    .services .form .label[for*='message'], .practice .form .label[for*='message'], .article .form .label[for*='message'], .contacts .form .label[for*='message'] {
      margin: 0; } }

@media screen and (max-width: 380px) {
  /* ---- ARTICLE PAGE---- */
  .article__item_pict {
    min-width: auto;
    width: 100%;
    float: none; } }
