/* BEGIN SELECTATOR PLUGIN */
.single .selectator_selected_items{
	display: flex;
	align-items: center;
	height: 100%;
}
.single .selectator_selected_items .selectator_selected_item .selectator_selected_item_title {
	color: $white;
	font-size: 1.2rem;
	line-height: 80%;
	font-family: $base-font-family;
}
.selectator_element.single {
	width: 100% !important;
	margin: 10px 0;
	padding: 0 20px 0 10px !important;
	border: none;
	border-radius: 2px;
	background: transparent;
	cursor: pointer; }
.selectator_element:after {
	content: ' ';
	position: absolute;
	top: 15px !important;
	right: 5px !important;
	width: 0;
	height: 0;
	overflow: hidden;
	border-top: 9px solid $nav-color;
	border-right: 6px solid transparent;
	border-left: 6px solid transparent;
	background: none;
	transform: none; }
.selectator_element{
	&:hover{
		box-shadow: none;
	}
	&.focused{
		box-shadow: none;
	}
}
.selectator_options {
	background: #f4f1f1; }
.selectator_options .selectator_option {
	padding: 3px 5px;
	min-height: 18px;
	background: #f4f1f1;
	-webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0);
	box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0);
	border: none;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-ms-align-items: center;
	align-items: center; }
.selectator_options .selectator_option .selectator_option_left img {
	height: 20px; }
.selectator_options .selectator_option .selectator_option_title {
	margin-left: 5px; }
.form__group-country .single .selectator_selected_items .selectator_selected_item .selectator_selected_item_title {
	margin-left: 45px; }
.selectator_options .selectator_option_title {
	font-size: 1.2rem; }
.selectator_options .selectator_option.active {
	background-color: $btn-color; }

.single .selectator_selected_items .selectator_selected_item .selectator_selected_item_left {
	margin-top: 10px;
}



/* END SELECTATOR PLUGIN */