
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer{
	width:100%;
	min-width: $min-width;
	order: 3;
}
.footer{
	&__top{
		padding: 2% 10%;
	}
	p{
		text-align: center;
		margin: 0 0 2% 0;
	}
}
