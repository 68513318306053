/* reset */
$min-width: 320px; 
$max-width: 1920px;
$base-bg: #fffee0;
$base-font-size: 1.2rem;
$base-line-height: 1.2rem;
$base-font-family: 'Rubik', sans-serif;
$custom-font-family: 'TrajanPro';
$link-color: #222222;
$default-transition:all 0.2s linear;

/* main variables */

//color
$def-color: transparent;
$text-color: #222222;
$title-color: #222222;
$nav-color: #fffee0;
$nav-color-current: #e4d36c;
$btn-color: #1c6352;
$gold: linear-gradient(263deg, #bb891f 0%, #f7f58f 50%, #bb891f 100%);
$blue: #03a5d3;
$blueBtn: linear-gradient(to top, #03a5d3 0%, #09c6f5 100%);
$green: #008000;
$greenLight: #79bf42;
$greenBtn: linear-gradient(to top, #7bb461 0%, #8dd070 100%);
$orange: #f38126;
$red: #ff0000;
$redBtn: #f64b00;
$gray: #666666;
$grayBtn: #c5c5c5;
$grayItem: rgba(230, 232, 236, 1);
$grayBg: #f2f2f2;
$white: #ffffff;
$link: #0099cc;
$facebook: #3b5998;

//text, titles
$title-font-family: $custom-font-family;

$font-size-h1: 5.5rem;
$line-height-h1: 5.5rem;
$font-size-h2: 2.1rem;
$line-height-h2: 2.1rem;
$font-size-h3: 1.5rem;
$line-height-h3: 100%;