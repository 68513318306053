@media screen and (max-width: 1600px) {
  html{
    font-size: 15px;
  }
  .header {
    &__top{
      padding: 2% 3% 3% 3%;
    }
    &__content{
      padding: 0 2%;
    }
  }
  .footer {
    &__top{
      padding: 2% 3%;
    }
  }
  [class*='content-left'], [class*='content-right']{
    .content{
      &__box{
        min-width: 410px;
      }
      &-name{
        margin: 0 0 5% 0;
        h4{
          line-height: 100%;
        }
        p{
          padding: 1.0% 0;
        }
      }
    }
  }
  [class*='content-left']{
    .content{
      &__box{
        padding: 0 4% 0 0;
      }
    }
  }
  [class*='content-right']{
    .content{
      &__box{
        padding: 0 0 0 4%;
      }
    }
  }
  [class*="nav"]{
    ul{
      li{
        margin: 0 3%;
      }
    }
  }
  .content{
    section{
      padding: 3%;
      & > .title, & > .title-invert{
        margin: 0 0 3% 0;
      }
    }
    &__item{
      padding: 2%;
      margin: 1%;
    }
    &__service{
      .content{
        &__box{
          margin: 0;
        }
        &__item{
          width: 270px;
          height: 270px;
          margin: 0;
        }
      }
      .slider{
        &-item {
          li {
            margin: 1%;
          }
        }
      }
    }
    &__contacts{
      padding: 2% 0 0 0 !important;
      [class*='content-left']{
        padding: 0 0 0 2%;
        .content{
          &__box{
            padding: 1% 3% 1% 1%;
          }
        }
      }
      [class*='content-right']{
        padding: 0 2% 0 0;
        .content{
          &__box{
            padding: 1% 1% 1% 3%;
          }
        }
      }
    }
    &__comment {
      .slick-dots {
        bottom: 35px;
      }
      .slick-prev,
      .slick-next
      {
        top: auto;
        bottom: -10px;
      }
      .slick-prev:before,
      .slick-next:before
      {
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
      }
      .slick-prev
      {
        left: calc(50% - 150px);
      }
      [dir='rtl'] .slick-prev
      {
        right: calc(50% - 150px);
      }
      .slick-next
      {
        right: calc(50% - 150px);
      }
      [dir='rtl'] .slick-next
      {
        left: calc(50% - 150px);
      }
    }
  }
  /* ---- SERVICES PAGE---- */
  .slider {
    &-services {
      .title{
        padding: 0 0 0 380px;
        top: 5%;
      }
    }
  }
  /* ---- CONTACTS PAGE---- */
  .contacts{
    section{
      padding: 2% 0 0 0 !important;
    }
    .content {
      &__box {
        min-width: auto;
      }
    }
    [class*='content-left'], [class*='content-right']{
      flex: 1 1 60%;
    }
    [class*='content-left']{
      padding: 0 0 0 2%;
      &.form{
        &-contacts {
          flex: 1 1 40%;
          padding: 2rem 1rem 2rem 2%;
        }
      }
    }
    [class*='content-right']{
      padding: 0 2% 0 0;
      &.form{
        &-contacts {
          flex: 1 1 40%;
          padding: 2rem 2% 2rem 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px){
  html{
    font-size: 14px;
  }
  .header {
    &__content{
      padding: 0;
      [class*='content-left'], [class*='content-right']{
        flex-direction: column;
        .content{
          &__box{
            min-width: auto;
            padding-bottom: 5%;
            justify-content: space-between;
            order: 1;
          }
          &-foto{
            order: 2;
            justify-content: center;
            img{
              width: auto;
              max-height: 400px;
              height: 100%;
              margin: auto;
            }
          }
          &-name{
            margin: 3% 0;
          }
        }
      }
    }
  }
  .content{
    section{
      padding: 10px 5px;
      &.slider-services{
        padding: 3%;
      }
    }
    &__service{
      .content{
        &__item{
          width: 215px;
          height: 215px;
          padding: 5px;
          &-icon{
            margin: 15px 0 0 0;
            &:after{
              bottom: -15px;
            }
          }
          h3{
            margin: 30px 0 0 0;
          }
        }
      }
    }
    &__contacts{
      [class*='content-left'], [class*='content-right'] {
        flex: 1 1 100%;
        order: 1;
        .content {
          &-foto {
            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      .map{
        height: 350px;
        order: 2 !important;
      }
    }
    &__howWeWork{
      .content{
        &__item{
          margin: 10px;
          display: inline-block;
        }
      }
    }
  }
  .canvas{
    display: none;
  }
  /* ---- SERVICES, PRACTICE AND ARTICLE PAGE---- */
  .services, .practice, .article{
    .form{
      max-width: 600px;
      margin: auto;
      &__box{
        flex-direction: column;
      }
      &__group{
        &-check{
          .check-input_label{
            order: 1;
          }
          .label{
            min-width: auto;
            order: 2;
          }
        }
      }
      .label{
        min-width: 130px;
      }
    }
  }
  /* ---- SERVICES PAGE ---- */
  .slider {
    &-services {
      .title{
        padding: 0 0 0 300px;
        left: 3%;
      }
      .slider {
        &-target {
          max-width: 300px;
          min-width: 300px;
          li {
            margin: 5px 0;
            span {
              &:last-of-type {
                &:after {
                  bottom: -5px;
                }
              }
            }
          }
          &-icon{
            min-width: 50px;
          }
          img {
            height: 30px;
          }
        }
      }
    }
  }


}


@media screen and (max-width: 991px) {
  html{
    font-size: 13px;
  }
  [class*="nav"]{
    width: 100%;
    ul{
      li{
        margin: 0 2%;
      }
    }
  }
  [class*="btn"] {
    max-width: 200px;
  }
  [class*='content-left'], [class*='content-right'] {
    .content {
      &__box {
        max-width: none;
        min-width: auto;
      }
      &-foto{
        max-width: 170px;
      }
    }
  }
  .header, .footer{
    &__top{
      flex-direction: column;
    }
  }
  .header {
    &__top{
      padding: 2% 1% 20px 1%;
    }
    &__content{
      [class*='content-left'], [class*='content-right']{
        flex-direction: row;
        padding: 1%;
        .content{
          &-foto{
            min-width: 100px;
            width: 100px;
            min-height: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            padding: 1%;
            background: $gold;
            img{
              width: 120px;
              max-height: none;
              height: auto;
            }
          }
        }
      }
      [class*='content-left']{
        .content{
          &__box{
            order: 2;
          }
          &-foto{
            order: 1;
          }
        }
      }
      [class*='content-right']{
        .content{
          &__box{
            order: 1;
          }
          &-foto{
            order: 2;
          }
        }
      }
    }
  }
  .footer {
    &__top{
      padding: 2% 1%;
    }
  }
  .content{
    &__item{
      margin: 5px;
    }
    &__comment{
      .content{
        &__item{
          &:before{
            left: 10px;
          }
          span{
            right: 10px;
          }
          h3{
            right: 10px;
          }
        }
      }
    }
    &__howWeWork{
      .content{
        &__item{
          width: 200px;
          height: 200px;
          margin: 15px;
          &:before{
            height: 160px;
          }
          span.border{
            width: 160px;
          }
        }
      }
    }
  }
  /* ---- PRACTICE PAGE---- */
  .practice{
    & > .content {
      &__box {
        align-items: stretch;
      }
    }
    &__column{
      flex: 1 1 100%;
      padding: 1rem;
      &:not(:last-child){
        .practice__item{
          &:last-child{
            &:after{
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              margin: auto;
              background-color: $btn-color;
              position: absolute;
              bottom: 20px;
              left: 0;
              z-index: 1;
              transform: scale(0.5);
              transform-origin: center;
              transition: all 0.4s linear;
            }
            &:hover{
              &:after{
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
  /* ---- CONTACTS PAGE---- */
  .contacts{
    [class*='content-left'], [class*='content-right']{
      flex: 1 1 45%;
      .content{
        &-foto{
          min-width: 100px;
          width: 100px;
          min-height: 100px;
          height: 100px;
          margin: 2rem 1rem;
          border-radius: 50%;
          overflow: hidden;
          padding: 1%;
          background: $gold;
          align-items: flex-start;
          img{
            width: 120px;
            max-height: none;
            height: auto;
          }
        }
      }
    }
    [class*='content-left']{
      &.form{
        &-contacts {
          flex: 1 1 45%;
        }
      }
    }
    [class*='content-right']{
      &.form{
        &-contacts {
          flex: 1 1 45%;
        }
      }
    }
  }
  /* ---- CONTACTS PAGE---- */
  .contacts{
    section{
      & > .content {
        &__box {
          flex-direction: column;
        }
      }
    }
    [class*='content-left'], [class*='content-right']{
      padding: 10px 5px;
      order: 1;
      &.form{
        &-contacts {
          padding: 10px 0;
          order: 2;
          h4{
            text-align: center;
          }
        }
      }
      .content{
        &__box, &-name{
          width: 100%;
          padding: 0;
        }
        &-foto{
          margin: 0;
          position: absolute;
          top: 10px;
          left: 5px;
          right: auto;
          bottom: auto;
        }
        &-name{
          max-width: 600px;
          margin: auto;
          padding: 0 5px;
          h4, p{
            text-align: left !important;
            padding: 0 0 0 100px;
          }
          h4{
            padding-bottom: 1rem;
          }
        }
      }
    }
    .form{
      margin: auto;
      padding: 0 10px;
    }
    [class*='btn']{
      align-self: center !important;
    }
    .map{
      height: 250px;
    }
  }
}

@media screen and (max-width: 700px) {
  .hamburger {
    &-box {
      display: block;
    }
  }
  [class*="nav"] {
    ul {
      flex-direction: column;
      align-items: flex-start;
      li {
        display: block;
        margin: 3%;
      }
    }
  }
  .nav {
    &-header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 50%;
      height: 100vh;
      background: linear-gradient(to right, rgba(46, 45, 45, 1) 10%, rgba(0, 0, 0, 1) 43%, rgba(13, 10, 9, 1) 50%, rgba(13, 11, 11, 1) 57%, rgba(46, 45, 45, 1) 90%);
      transition: 0.5s;
      transform-origin: left;
      transform: perspective(2000px) rotateY(-90deg);
    }
    &-footer {
      display: none;
    }
  }
  .header {
    &__content{
      flex-direction: column;
      [class*='content-left']{
        .content{
          &__box {
            padding: 0 10px 0 100px;
          }
          &-foto{
            top: 20px;
            left: 10px;
          }
          &-name{
            align-self: flex-end;
            h4,p{
              text-align: right;
            }
          }
        }
        [class*='btn']{
          align-self: flex-end;
        }
      }
      [class*='content-right']{
        .content{
          &__box {
            padding: 0 100px 0 10px;
          }
          &-foto{
            bottom: 20px;
            right: 10px;
          }
          &-name{
            align-self: flex-start;
            h4,p{
              text-align: left;
            }
          }
        }
        [class*='btn']{
          align-self: flex-start;
        }
      }
      [class*='content-left'], [class*='content-right']{
        .content {
          &__box {
            width: 100%;
            padding-bottom: 10px;
            justify-content: center;
          }
          &-foto{
            position: absolute;
          }
          &-name{
            max-width: 70%;
          }
        }
        [class*='btn']{
          margin: 0;
        }
      }
    }
    [class*="content-bg"]{
      img{
        width: 100%;
        transform: rotate(90deg) scale(3);
      }
    }
  }
  .footer {
    &__top{
      width: auto;
      display: inline-block;
    }
    p{
      display: inline-block;
      width: calc(100% - 120px);
      span{
        display: block;
      }
    }
  }
  .content{
    &__service{
      .content{
        &__item{
          width: 100%;
          height: 100%;
          &-icon{
            margin: 15px 0 0 0;
            &:after{
              bottom: -15px;
            }
          }
          h3{
            margin: 30px 0 0 0;
          }
        }
      }
      .slider{
        &-target {
          width: 260px;
          display: flex;
          flex-wrap: wrap;
        }
        &-item {
          min-width: 195px;
          width: 195px;
          min-height: 195px;
          height: 100%;
          li {
            width: 100%;
            height: 100%;
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            .content__item {
              display: block;
              position: relative;
              &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $nav-color;
              }
            }
          }
          &-target {
            z-index: 3 !important;
            animation: slide 1s 1;
            .content__item{
              background: $gold;
              &:after {
                display: none;
              }
            }
          }
          @keyframes slide {
            0% { transform: translateY(100%); }
            100% { transform: translateY(0%); }
          }
          &:not(.slider-item-target) {
            animation: hidden 1s 1;
          }
          @keyframes hidden {
            0% { z-index: 2; }
            100% { z-index: 2; }
          }
        }
      }
    }
    .contacts{
      .map{
        height: 200px;
      }
    }
    &__comment{
      [class*='title']{
        margin: 0 0 30px 0 !important;
      }
      .content{
        &__box {
          align-items: center;
        }
        &__item{
          width: 100%;
          height: auto;
          margin: 0 20px;
          padding: 30px 0;
          border: none;
          &:before{
            width: 40px;
          }
          &:after{
            content: '';
            position: absolute;
            top: 5px;
            right: 10px;
            width: calc(100% - 70px);
            height: 1px;
            background: $gray;
          }
          &:hover{
            border: none;
            box-shadow: none;
          }
          span{
            top: 7px;
          }
          h3{
            top: -17px;
          }
        }
      }
    }
    &__howWeWork{
      .content{
        &__item{
          width: 100%;
          height: auto;
          padding: 0 0 2% 40px;
          margin: 10px;
          border-bottom: 1px solid $gray;
          border-right: none;
          &:after{
            top: -15px;
          }
          &:before{
            display: none;
          }
          h3{
            padding: 0;
          }
          span.border{
            display: none;
          }
        }
      }
      &_row{
        flex-wrap: wrap;
        justify-content: center;
        &:last-of-type{
          .content {
            &__item {
              &:last-of-type{
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  /* ---- SERVICES PAGE---- */
  .slider {
    &-services {
      flex-direction: column;
      [class*='title']{
        padding: 0;
        position: static;
        order: 1;
      }
      .slider {
        &-target {
          order: 3;
          max-width: none;
          min-width: auto;
        }
        &-item{
          order: 2;
          li{
            top: 0;
          }
        }
      }
    }
  }
  /* ---- ARTICLE PAGE---- */
  .article{
    &__item{
      &_pict{
        min-width: 200px;
        width: 200px;
      }
      &_date{
        right: 1rem;
        span{
          padding: 0 0 0 30px;
          &:after{
            width: 20px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 480px) {
 .title, .title-invert{
   font-size: 24px;
 }
 .nav{
   &-header {
     width: 100%;
   }
 }
 .header {
   &__content{
     [class*='btn']{
       margin: 0;
     }
     [class*='content-left']{
       .content{
         &__box{
           padding: 1% 5px 1% 110px;
         }
         &-foto{
           top: 10%;
           bottom: auto;
         }
       }
     }
     [class*='content-right']{
       .content{
         &__box{
           padding: 1% 110px 1% 5px;
         }
         &-foto{
           top: 20%;
           bottom: auto;
         }
       }
     }
     [class*='content-left'],[class*='content-right']{
       .content{
         &-name{
           max-width: none;
           width: 100%;
           h4{
             text-align: center;
           }
           p{
             text-align: left;
           }
         }
       }
     }
   }
 }
 .content{
   &__service{
     .content{
       &__item{
         &-icon{
           margin: 20px 0 0 0;
           &:after{
             bottom: -20px;
           }
         }
         h3{
           margin: 40px 0 0 0;
         }
       }
     }
     .slider{
       &-service{
         flex-direction: column;
       }
       &-item {
         min-width: 260px;
         width: 260px;
         min-height: 260px;
       }
     }
   }
   &__contacts{
     [class*='content-left'],[class*='content-right']{
       .content{
         &__box{
           width: 100%;
         }
         &-foto{
           display: none;
         }
         &-name{
           width: 100%;
           h4{
             text-align: center;
           }
           p{
             text-align: left;
           }
         }
       }
       [class*='btn']{
         align-self: center;
       }
     }
   }
 }
 /* ---- SERVICES, PRACTICE, ARTICLE AND CONTACTS PAGE---- */
  .services, .practice, .article, .contacts{
    .form{
      &__group{
        &:not(.form__group-check){
          flex-direction: column;
          align-items: flex-start;
          &.activate{
            .label {
              padding: 5px 0 0 0;
            }
          }
        }
        &-check{
          .label{
            font-size: 1.2rem;
            white-space: pre-wrap;
          }
        }
      }
      .text-input{
        padding: 0;
      }
      .textarea{
        padding: 10px 0 0 0;
      }
      .selectator_element.single {
        padding: 0 20px 0 0 !important;
      }
      .label{
        min-width: auto;
        &[for*='message']{
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  /* ---- ARTICLE PAGE---- */
  .article {
    &__item {
      &_pict {
        min-width: auto;
        width: 100%;
        float: none;
      }
    }
  }
}