/*
================================================================================
|                                     ALL                                |
================================================================================
*/
/* ---- MAIN STYLES ---- */
html{
  font-size: 16px;
}
.main{
  &-wrapper{
    max-width: $max-width;
    min-width: $min-width;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    &.home{
      .footer__join{
        display: block !important;
      }
    }
  }
  &-section{
    max-width: 1500px;
    min-width: 1500px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
}

/* ---- TITLES ---- */

*a{
  &:link, &:hover, &:active, &:visited{
    text-decoration: none;
  }
}
p{
  @include font($base-font-size,$base-line-height,$text-color);
  text-align: justify;
  font-weight: 400;
}
h1{
  @include font($font-size-h1,$line-height-h1,$title-color);
  font-family: $title-font-family;
  font-weight: 700;
}
h2{
  @include font($font-size-h2,$line-height-h2,$title-color);
  font-family: $title-font-family;
  font-weight: 400;
}
h3{
  @include font($font-size-h3,$line-height-h3,$text-color);
  font-family: $base-font-family;
  font-weight: 700;
}
.title, .title-invert{
  width: 100%;
  font: normal 700 2.625rem / 100% $title-font-family;
  text-align: center;
}
.title{
  color: $text-color;
  &-invert{
    color: $nav-color;
  }
}


/* ---- LOGO ---- */
.logo{
  display: block;
  width: 100px;
  position: relative;
  &:link, &:hover, &:active, &:visited {
    text-decoration: none;
  }
  img{
    box-sizing: border-box;
    width: 100%;
  }
}
/* ---- BACKGROUND RESPONSIVE---- */
[class*='content-bg']{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  img{
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}
/* ---- DECORATIVE ELEMENTS---- */
[class*='line']{
  &:after{
    position: absolute;
    left: 0;
    bottom: -4px;
    z-index: 2;
  }
}
[class*='line-gold']{
  &:after{
    content: url("../images/line-gold.png");
  }
}
[class*='line-green']{
  &:after{
    content: url("../images/line-green.png");
  }
}
[class*='shadow']{
  &:before{
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 15px;
    background: linear-gradient(to bottom, rgba(46,45,45, 0.8) 0%, rgba(46,45,45, 0.4) 30%, rgba(46,45,45, 0.1) 50%, transparent 100%);
  }
}

/* ---- NAVIGATION ---- */
[class*="nav"]{
  flex: 1 1 100%;
  ul{
    list-style: none;
    display: flex;
    //justify-content: space-between;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    li{
      display: inline-block;
      margin: 0 5%;
    }
  }
}
.nav{
  &__link{
    font-size: 1.4rem;
    white-space: nowrap;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      transform: scale(0);
      transform-origin: center center;
      transition: all 0.4s linear;
    }
    &:link, &:active, &:visited {
      color: $nav-color;
    }
    &:hover{
      &:after{
        transform: scale(1);
      }
    }
    &-current{
      &:after{
        transform: scale(1);
      }
    }
  }
  &-header{
    .nav{
      &__link{
        color: $nav-color;
        &:after{
          background: $nav-color;
        }
        &:link, &:active, &:visited {
          color: $nav-color;
        }
        &-current{
          color: $nav-color-current !important;
          &:after{
            background: $nav-color-current;
          }
        }
      }
    }
  }
  &-footer{
    .nav{
      &__link{
        color: $text-color;
        &:after{
          background: $text-color;
        }
        &:link, &:active, &:visited {
          color: $text-color;
        }
        &-current{
          color: $text-color !important;
          &:after{
            background: $text-color;
          }
        }
      }
    }
  }
}

.hamburger{
  &-box{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9000;
  }
  &-menu{
    position: relative;
    width: 60px;
    height: 60px;
    pointer-events: none;
    transition: 0.1s;
    svg{
      position: absolute;
      top: -25px;
      left: -25px;
      transform: scale(1.0);
      transform-origin: 0 0;
      path{
        stroke-width: 5px;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: transparent;
        &:nth-of-type(1){
          stroke-dashoffset: 580.315px;
          stroke-dasharray: 290.158, 298.158, 24;
        }
        &:nth-of-type(2){
          stroke-dashoffset: 80px;
          stroke-dasharray: 40, 48, 24;
        }
        &:nth-of-type(3){
          stroke-dashoffset: 699.312px;
          stroke-dasharray: 349.656, 357.656, 24;
        }
      }
    }
    &-header svg path{
      stroke: url('#grad') $nav-color-current;
    }
  }
  &-buton{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    &:hover, &:focus {
      outline: none;
    }
  }
}

.pushed{
  .nav-header {
    transform: perspective(2000px) rotateY(0deg);
  }
  .header__content, main, footer{
    transition: all 0.5s linear;
    position: relative;
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      background-color:#000;
      opacity:0.5;
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
    }
  }
}

.open{
  &-0{
    animation: open-A 0.5s linear forwards;
    @keyframes open-A {
      0% {
        stroke-dasharray: 290.158, 298.158, 24;
      }
      100% {
        stroke-dasharray: 290.158, 525.815, 24;
      }
    }
  }
  &-1{
    animation: open-B 0.5s linear forwards;
    @keyframes open-B {
      0% {
        stroke-dasharray: 40, 48, 24;
      }
      100% {
        stroke-dasharray: 40, 60, 0;
      }
    }
  }
  &-2{
    animation: open-C 0.5s linear forwards;
    @keyframes open-C {
      0% {
        stroke-dasharray: 349.656, 357.656, 24;
      }
      100% {
        stroke-dasharray: 349.656, 644.812, 24;
      }
    }
  }
  &-scale{
    animation: open-scale 0.5s linear forwards;
    @keyframes open-scale {
      0% {
        transform: scale(0.5);
        top: 0px;
        left: 0px;
      }
      100% {
        transform: scale(1.0);
        top: -25px;
        left: -25px;
      }
    }
  }
}

.close{
  &-0{
    animation: close-A 0.5s linear forwards, shake-A 0.3s linear forwards 0.5s;
    @keyframes close-A {
      0% {
        stroke-dasharray: 290.158, 525.815, 24;
      }
      100% {
        stroke-dasharray: 290.158, 298.158, 24;
      }
    }
  }
  &-1{
    animation: close-B 0.5s linear forwards;
    @keyframes close-B {
      0% {
        stroke-dasharray: 40, 60, 0;
      }
      100% {
        stroke-dasharray: 40, 48, 24;
      }
    }
  }
  &-2{
    animation: close-C 0.5s linear forwards, shake-C 0.3s linear forwards 0.5s;
    @keyframes close-C {
      0% {
        stroke-dasharray: 349.656, 644.812, 24;
      }
      100% {
        stroke-dasharray: 349.656, 357.656, 24;
      }
    }
  }
  &-scale{
    animation: close-scale 0.5s linear forwards;
    @keyframes close-scale {
      0% {
        transform: scale(0.5);
        top: 0;
        left: 0;
      }
      100% {
        transform: scale(1.0);
        top: -25px;
        left: -25px;
      }
    }
  }
}

@keyframes shake-A {
  0%{
    transform: translate3d(0, 0, 0);
  }
  10%, 50%, 90% {
    transform: translate3d(7%, 0, 0);
  }
  30%, 70% {
    transform: translate3d(-7%, 0, 0);
  }
  100%{
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shake-C {
  0%{
    transform: translate3d(0, 0, 0);
  }
  10%, 50%, 90% {
    transform: translate3d(-7%, 0, 0);
  }
  30%, 70% {
    transform: translate3d(7%, 0, 0);
  }
  100%{
    transform: translate3d(0, 0, 0);
  }
}

[class*="btn"] {
  max-width: 300px;
  width: 100%;
  max-height: 50px;
  padding: 3% 5px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 2px;
  background: $gold;
  box-shadow: -0.9px 1.8px 4px rgba(58, 41, 0, 0.49);
  color: $btn-color;
  font: normal 700 1.2rem $base-font-family;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear;
  &:link, &:hover, &:active, &:visited{
    color: $btn-color;
    text-decoration: none;
  }
  &:active {
    position: relative;
    top: 1px;
    left: 1px;
  }
}

[class*='nav-arrow'] {
  position: relative;
  cursor: pointer;
  &:after {
    content: ' ';
    position: absolute;
    top: calc(50% - 3px);
    right: 5px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 9px solid $text-color;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}

/* ---- RESPONSIVE ELEMENTS ---- */
[class*='pict_responsive']{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img{
    display: block;
    box-sizing: border-box;
  }
}
[class*="table_responsive"]{
  width: 100%;
  margin: 0 auto;
  thead tr th{
    padding: 3% 3% 0 3%;
  }
  tbody tr {
    td{
      padding: 0 3%;
    }
  }
  td, th{
    color: $text-color;
    font: normal 400 1.2rem / 100% 'Lato', sans-serif;
  }
  thead tr th{
    color: $title-color;
    font-weight: 700;
  }
}
/* ---- FORM ELEMENTS ---- */
.g-recaptcha > div {
  margin: 10px auto !important;
  height: 79px!important;
  overflow: hidden;
  border-radius: 10px;
}
.rc-anchor{
  width: 100%;
  border-radius: 0!important;
}
.rc-anchor-content{
  width: 100%!important;
  height: 100%!important;
}
.rc-anchor-compact-footer {
  display: none;
}

.form{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &_bg{
    padding: 3%;
    background: $white;
    border-radius: 5px;
  }
  &__box{
    display: flex;
    align-items: center;
  }
  &__group {
    margin: 10px auto;
    display: flex;
    align-items: center;
    position: relative;
    &:not(.form__group-check){
      &:before, &:after {
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 0;
        transform: scale(1);
        transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      &:before {
        top: 0;
        border-top: 2px solid $white;
        transform-origin: left;
      }
      &:after {
        bottom: 0;
        border-bottom: 2px solid $white;
        transform-origin: right;
      }
      &:hover, &.focus, &.activate {
        &:before {
          animation: border-top 1s linear forwards;
          @keyframes border-top {
            0% {
              transform: scale(1);
              border-top: 2px solid $white;
            }
            50% {
              transform: scale(0);
              border-top: 2px solid $white;
            }
            100% {
              transform: scale(1.0);
              border-top: 2px solid $nav-color-current
            }
          }
        }
        &:after {
          animation: border-bottom 1s linear forwards;
          @keyframes border-bottom {
            0% {
              transform: scale(1);
              border-bottom: 2px solid $white;
            }
            50% {
              transform: scale(0);
              border-bottom: 2px solid $nav-color-current;
            }
            100% {
              transform: scale(1.0);
              border-bottom: 2px solid $nav-color-current
            }
          }
        }
        .label {
          color: $nav-color-current !important;
        }
      }
      &.activate{
        .label {
          font-size: 0.75rem !important;
          line-height: 1 !important;
          text-transform: uppercase;
          padding: 0;
        }
      }
    }
    &-full{
      flex-direction: column;
      align-items: flex-start;
      .label{
        min-width: auto;
      }
    }
    &_box{
      width: 100%;
      display: flex;
    }
    &-file{
      align-items: flex-start;
      .text-input {
        display: none !important;
      }
      .label{
        min-width: 160px;
        height: 50px;
        display: flex;
        align-items: center;
      }
      label {
        display: block;
        width: 100%;
        padding: 0;
        line-height: 30px;
        position: relative;
      }
      .file{
        &-not {
          width: 100% !important;
          min-height: 40px;
          height: auto !important;
          padding: 0 0 0 165px;
          &[class*="status"]{
            &:not([class*="btn"]){
              &:before {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 30px;
                height: 30px;
                z-index: 10;
              }
            }
          }
          &:after {
            content: attr(data-btn);
            position: absolute;
            left: 0;
            top: 0;
            bottom: auto !important;
            display: block;
            width: 150px;
            min-height: 40px;
            padding: 5px;
            margin: auto;
            border: none;
            border-radius: 2px;
            background-image: $blueBtn;
            color: $white;
            font: normal 600 1.25rem $base-font-family;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            transition: all 0.2s linear;
          }
          &:after {
            width: 160px !important;
            padding-left: 40px;
            text-align: left;
            right: 100% !important;
          }
          span:not(.file-max){
            height: 100%;
            line-height: 40px;
          }
          .file-max{
            height: 100%;
            line-height: 100%;
            b{
              color: $red;
            }
          }
          &:hover{
            &:after {
              box-shadow: 1px 1px 0px $title-color, 1px 1px 0px $title-color, 2px 2px 0px $title-color, 2px 2px 0px $title-color, 3px 3px 0px $title-color, 3px 3px 0px $title-color, 4px 4px 0px $title-color, 5px 5px 0px $title-color;
            }
          }
        }
        &-not_box, &_box {
          width: 100%;
          height: auto;
          padding: 5px 0;
          cursor: pointer;
        }
      }
      .btnDelete{
        display: inline-block;
        max-width: 160px !important;
        height: 40px;
        margin: 5px 0;
        font-size: 1.25rem;
        line-height: 20px;
      }
    }
  }
  .file{
    &_preview{
      display: inline-block;
      width: 100px;
      height: 100px;
      margin: 5px;
      position: relative;
      &_box{
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      & > span {
        width: 100px;
        padding: 5px;
        background: $orange;
        color: $white;
        font-size: 1rem;
        text-align: center;
        word-break: break-all;
        position: absolute;
        top: 102%;
        left: 0;
        z-index: 100;
        display: none;
      }
      &:after {
        content: attr(data-del);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        color: $white;
        font: normal 600 1rem 'Lato', sans-serif;
        text-align: center;
        display: none;
      }
      &:hover{
        & > span, &:after{
          display: flex;
        }
      }
      &-del{
        border: 2px solid $red;
        &:after {
          display: flex;
        }
        & > span{
          left: -2px;
        }
      }
    }
    &_image{
      display: block;
      box-sizing: border-box;
      width:100%;
      margin: auto;
    }
  }
  .text-input, .textarea, .select, .label, .check-label{
    background: transparent;
    color: #222222;
    font: normal 400 1.2rem $base-font-family;
  }
  .text-input, .textarea, .select{
    margin: 10px 0;
  }
  .text-input, .textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background: transparent;
    color: #000000;
  }
  .text-input{
    padding: 0 0 0 10px;
  }
  .textarea{
    padding: 10px 0 0 10px;
    resize: none;
    overflow: auto;
  }
  .select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 0;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
  }
  [class*='check-input_label']{
    display: block;
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 1px solid $nav-color-current;
    border-radius: 2px;
    background: linear-gradient(to top, rgba(16,44,36,1) 0%, rgba(25,83,69,1) 50%, rgba(16,44,36,1) 100%);
    position: relative;
    cursor: pointer;
    &:after{
      content: ' ';
      display: block;
      position: absolute;
      left: 3px;
      top: 4px;
      width: 14px;
      height: 7px;
      border-left: solid 3px $white;
      border-bottom: solid 3px $white;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0;
    }
  }
  .check{
    &-input{
      display: none;
      &:checked{
        & + [class*='check-input_label'] {
          &:after {
            opacity: 1; }
        }
      }
      &_label-circle {
        min-width: 30px;
        width: 30px;
        height: 30px;
        margin: 5px;
        background: $white;
        border: 1px solid #b3b3b3;
        border-radius: 50%;
        box-shadow: inset -2px 2.2px 3px rgba(0, 0, 0, 0.66);
        &:after {
          left: 5px;
          top: 8px;
          width: 16px;
          height: 9px;
        }
      }
      &_label-buton {
        min-width: 40px;
        width: auto;
        height: 40px;
        margin: 0;
        padding: 0 5px;
        border: none;
        background: $greenBtn;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
          border: none;
        }
        &:hover{
          background: $orange;
        }
      }
    }
    &-label{
      color: #222222;
      a{
        color: $title-color !important;
        &:hover{
          text-decoration: underline;
        }
      }
      span{
        color: $orange;
      }
    }
  }
  .label {
    padding: 0;
    color: #222222;
    font-size: 1.6rem;
    white-space: nowrap;
    span{
      color: $orange;
    }
    &[for*='message']{
      align-self: flex-start;
      margin: 10px 0 0 0;
    }
  }
  &__recaptcha{
    margin: auto;
  }
  [class*="form__btn"] {
    //height: 40px;
    min-height: 50px;
    padding: 5px;
    text-transform: uppercase;
  }
  &__note{
    color: $orange;
    font: normal 400 1rem $base-font-family;
  }
  &__tooltip{
    width: 100%;
    display: none;
    padding: 3px 0 0 0;
    color:  $nav-color-current;
    font-size: 10px;
    text-align: right;
    position: absolute;
    left: 0;
    bottom: -10px;
  }
  &__invalid{
    &:not(.form__group-check) {
      border-top: 2px solid $red;
      border-bottom: 2px solid $red;
    }
    &:before {
      border-top: 2px solid $red !important;
    }
    &:after {
      border-bottom: 2px solid $red !important;
    }
    .text-input, .textarea, .selectator_element{
      //border: solid 1px $red !important;
    }
    &-label{
      color: $red !important;
    }
  }
  &__valid{
    &:not(.form__group-check) {
      border-top: 2px solid $green;
      border-bottom: 2px solid $green;
    }
    &:before {
      border-top: 2px solid $green !important;
    }
    &:after {
      border-bottom: 2px solid $green !important;
    }
    .text-input, .textarea, .selectator_element{
      //border: solid 1px $green !important;
    }
    &-label{
      color: $green !important;
    }
  }
  &__popUp{
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 1000;
    cursor:pointer;
    &-wrapper{
      width: 100%;
      height: 100%;
      position: relative;
    }
    &-bg{
      width: 100%;
      height: 100%;
      background-color:#000;
      opacity:0.4;
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
    }
    &-content{
      width: 300px;
      height: 300px;
      border-radius: 5px;
      background: $white;
      position: absolute;
      top: 45%;
      left: 50%;
      margin-top: -150px;
      margin-left: -150px;
      z-index: 100;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &-foto{
      max-width: 300px;
      max-height: 50px;
      img{
        display: block;
        box-sizing: border-box;
        height: 100%;
        margin: auto;
      }
    }
    &-text{
      padding: 1rem 0;
      color: #555;
      font: normal 400 16px / 18px $base-font-family;
      text-align: center;
    }
    &-close{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 9000;
      cursor: pointer;
      &__section{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__cross{
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        border: solid 2px $btn-color;
        span{
          width: 20px;
          height: 2px;
          position: absolute;
          top: 50%; margin-top: -1px;
          left: 50%; margin-left: -10px;
          &::before,&::after{
            content: " ";
            display: block;
            width: 20px;
            height: 2px;
            background: $btn-color;
            position: absolute;
            top: 50%; margin-top: -1px;
            left: 50%; margin-left: -10px;
          }
          &::before{
            transform: rotate(45deg);
          }
          &::after{
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  &-search {
    max-width: 250px;
    width: 100%;
    height: 40px;
    position: relative;
    .label{
      //line-height: 40px;
      white-space: nowrap;
    }
    .search {
      &_buton{
        background: none;
        position: absolute;
        top: 0;
        right: 100%;
        height: 40px;
        width: 40px;
        padding: 0;
        border-radius: 100%;
        outline: 0;
        border: 0;
        color: inherit;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        transform: translate(100%, 0%);
        &:before {
          content: "";
          position: absolute;
          top: 30px;
          right: 3px;
          width: 15px;
          height: 4px;
          background-color: $title-color;
          transform: rotate(45deg);
        }
        &.close {
          right: 0;
          transform: translate(0%, 0%);
          transition: 0.4s ease-in-out;
          &:before {
            width: 27px;
            top: 18px;
            right: 5px;
          }
          &:after {
            content: " ";
            position: absolute;
            top: 18px;
            right: 5px;
            width: 27px;
            height: 4px;
            background-color: $title-color;
            cursor: pointer;
            transform: rotate(-45deg);
          }
        }

      }
      &-input {
        width: 30px;
        height: 30px;
        border: 4px solid $title-color;
        border-radius: 50%;
        background: none;
        color: $text-color;
        font: normal 400 1.2rem $base-font-family;
        outline: 0;
        transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s;
        &::placeholder {
          color: $def-color;
        }
        &.square {
          padding: 0 40px 0 10px;
          width: 100%;
          height: 40px;
          border: 2px solid $title-color;
          border-radius: 0;
          transition: width 0.2s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s;
          transition-delay: 0.4s, 0s, 0.4s;
          &::placeholder {
            color: $gray;
          }
        }
      }
    }
    &-icon{
      [class*="form__btn"]{
        display: none;
        max-width: none !important;
        margin: 0 auto;
        margin-top: 10px !important;
        line-height: 100%;
      }
    }
  }
}
.password{
  &-input{
    display: inline-block;
    width: 100%;
    position: relative;
  }
  &__buton{
    width: 30px;
    height: 30px;
    background: url("../images/password-show.png") 50% 50% / 100% auto no-repeat;
    position: absolute;
    top: calc(50% - 15px);
    right: 5px;
    cursor: pointer;
  }
  &-show{
    background: url("../images/password-hide.png") 50% 50% / 100% auto no-repeat;
  }
}
.birthdate {
  &__box {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
  }
  &__month {
    flex: 1 1 auto;
    .selectator_element{
      max-width: 120px;
      float: right;
    }
  }
  &__data {
    width: 40px;
    .text-input{
      padding: 5px 0 0 5px;
    }
  }
  &__year {
    width: 60px;
  }

  .label{
    min-width: auto !important;
  }
  .form__tooltip{
    width: 100%;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
.pay-check{
  display: inline-block;
  width: 0px;
  height: 0px;
  position: relative;
  &:after{
    content: ' ';
    display: block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 20px;
    height: 10px;
    border-left: solid 3px $green;
    border-bottom: solid 3px $green;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}


/* ---- SERVICE CLASS---- */
[class*="-nowrap"]{
  flex-wrap: nowrap;
}
[class*="-stretch"]{
  flex: 1 1 100%;
}
.hidden{
  display: none !important;
}




@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body{min-height: 100vh; width: 100%; display: flex; flex-direction: column;}
}

