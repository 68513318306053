
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/

header{
	width:100%;
	min-width: $min-width;
}

.header {
	&__top{
		padding: 2% 10% 3% 10%;
		background: linear-gradient(to right, rgba(46,45,45,1) 10%, rgba(0,0,0,1) 43%, rgba(13,10,9,1) 50%, rgba(13,11,11,1) 57%, rgba(46,45,45,1) 90%);
	}
	&__content{
		width: 100%;
		padding: 0 5%;
		display: flex;
		justify-content: space-between;
		position: relative;
	}
}

.header, .footer{
	position: relative;
	&__top{
		width: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}


