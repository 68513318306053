/* Slider */

/*.slick-dotted.slick-slider {
    margin-bottom: 30px;
}*/


.slick-dots li {
    height: 15px;
    width: 15px;
    margin: 0 5px;
}
.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 15px;
    width: 15px;
    padding: 5px;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li{
    button{
        &:before {
            content: " ";
            width: 20px;
            height: 20px;
            border: 1px solid $btn-color;
            border-radius: 2px;
            background: $gold;
            opacity: 0.7;
        }
        &:after{
            content: ' ';
            display: block;
            position: absolute;
            left: 3px;
            top: 4px;
            width: 14px;
            height: 7px;
            border-left: solid 3px $btn-color;
            border-bottom: solid 3px $btn-color;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            opacity: 0;
        }
    }
    &.slick-active{
        button{
            &:after {
                opacity: 1.0;
            }
        }
    }
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    z-index: 100;
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    content: '';
    opacity: .75;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev:before
{
    border-right:  50px solid #cbcaca;
}
[dir='rtl'] .slick-prev:before
{
    border-right:  50px solid #cbcaca;
}


.slick-next:before
{
    border-left: 50px solid #cbcaca;
}
[dir='rtl'] .slick-next:before
{
    border-left: 50px solid #cbcaca;
}


.content__comment {
    .slick-list {
        padding: 30px 0 0 0 !important;
    }
    .slick-track{
        display: flex;
        align-items: stretch;
    }
    .slick-dots {
        width: auto;
        left: calc(50% - 50px);
        bottom: 10px;
    }
    .slick-prev
    {
        left: -50px;
    }
    [dir='rtl'] .slick-prev
    {
        right: -50px;
        left: auto;
    }
    .slick-prev:before,
    .slick-next:before
    {
        font-size: 0px;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
    }
    .slick-prev:before
    {
        border-right:  30px solid $btn-color;
    }
    [dir='rtl'] .slick-prev:before
    {
        border-right:  30px solid $btn-color;
    }
    .slick-next
    {
        right: -50px;
    }
    [dir='rtl'] .slick-next
    {
        right: auto;
        left: -50px;
    }
    .slick-next:before
    {
        border-left: 30px solid $btn-color;
    }
    [dir='rtl'] .slick-next:before
    {
        border-left: 30px solid $btn-color;
    }
}

